import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function EditGrid({ data = [], headers = [], headerName = "", clickRow = false, url = "/", urlId = "id", sortBy = { sortKey: "", sortDesc: true }, sortCallBack = () => { } }) {
    const [headerSort, setHeaderSort] = useState(0)
    const [dataList, setDataList] = useState(data)
    const [classes, setClasses] = useState([]);

    //const HeaderClick = (key) => {
    //    let sort = { ...sortBy }
    //    if (sort.sortKey === key) {
    //        sort.sortDesc = !sort.sortDesc
    //    } else {
    //        sort.sortKey = key
    //        sort.sortDesc = true
    //    }
    //    let s = [...data]
    //    function compare(a, b) {
    //        if (a[sort.sortKey] < b[sort.sortKey]) {
    //            return -1;
    //        }
    //        if (a[sort.sortKey] > b[sort.sortKey]) {
    //            return 1;
    //        }
    //        return 0;
    //    }
    //    s = s.sort(compare);
    //    if (sort.sortDesc) {
    //        s.reverse()
    //    }
    //    sortCallBack(s, sort)
    //}

    useEffect(() => {
        if (data) {
            setDataList(data)
        }
    }, [data])

    useEffect(() => {
        let newArry = [];
        headers.forEach((header) => {
            newArry.push("clickable")
        })
        setClasses(newArry)
    },[])

    const handleSortBy = (headerID) => {
        let x = headerID

        sortBy = { sortKey: headers[x].key, sortDesc: true }
    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
          
        })

        setClasses(newArr)

    }

    const handleSort = (headerID, direction) => {

        setClass(headerID)

        let s = [...dataList]

        handleSortBy(headerID)

        if (direction === 1) {
            setHeaderSort(2)
            sortBy.sortDesc = false;
        }
        if (direction === 2) {
            setHeaderSort(1)
            sortBy.sortDesc = true;
        }

        function compare(a, b) {
            var lhs = a[sortBy.sortKey];
            var rhs = b[sortBy.sortKey];
            if (lhs < rhs) {
                return -1;
            }
            if (lhs > rhs) {
                return 1;
            }
            return 0;
        }
        s = s.sort(compare);
        if (sortBy.sortDesc) {
            s.reverse()
        }
        sortCallBack(s, sortBy)
    }


    return (
        <div className="container-fluid my-2">
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    {headerName ? headerName : "Data Grid"}
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        {headers.map((m, i) => {
                            return (
                                <div className={`col`} key={`header-${i}`}>
                                    {m.name}{headerSort === 0 || headerSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[i]} onClick={() => handleSort(i, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[i]} onClick={() => handleSort(i, 2)} />}
                                </div>
                            )
                        })}
                    </div>
                </ListGroup.Item>
                {data.map((m, i) => {
                    let AdditionalParams = {}
                    if (clickRow) {
                        AdditionalParams["as"] = Link
                        AdditionalParams["to"] = `${url}/${m[urlId]}`
                        AdditionalParams["className"] = "click-list"
                    }

                    return (
                        <ListGroup.Item key={`SDG-${i}`} {...AdditionalParams} >
                            <div className="row">
                                {headers.map((h, hi) => {
                                    return (
                                        <div className="col" key={`SDG-DC-${hi}`}>
                                            {h.input ? <input className="enc-input-100" value={m[h.key]} onChange={e => h.changeFunction(i, e.target.value)} /> : m[h.key]}
                                        </div>
                                    )
                                })}
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default EditGrid