import React, { useEffect, useState } from 'react';
import { Nav, ListGroup } from 'react-bootstrap';
import { NavLink, Switch, Route, useHistory, } from 'react-router-dom';
import { GetJobProfile, CheckDrillingAuth } from '../API/Jobs';
import Stage1View from './HandOff/Stage1View';
import SelectScreen from './SelectScreen';
import NewNote from './NewNote';
import NoteView from './NoteView';
import ApprovalMain from './Approvals/ApprovalMain';
import InProgress from './InProgress/InProgress';
import JobReminder from './JobReminder';
import Stage2Router from './Stage2/Stage2Router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ClosedJobView from './ClosedJobView';
import CloneButton from './CloneButton';
import CloneJob from './CloneJob';

export default props => {
    const [prevailingWage, setPrevailingWage] = useState(undefined);
    const [profile, setProfile] = useState({
        "jobId": 0,
        "jobNumber": "",
        "jobName": "",
        "jobDescription": "",
        "canceled": true,
        "opportunityId": 0,
        "jobClassification": "",
        "jobStage": "",
        "jobStep": "",
        "jobStepId": -1,
        "completedBy": null,
        "completedDate": null,
        "lastUpdated": null,
        "shippingLocation": {
            "accountLocationId": 0,
            "accountId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true,
            "jobLocation": true
        }
    });
    const jobId = props.match.params.jobId;
    const url = props.match.url;
    const stageUrl = profile.jobStage.replace(/\s/g, '')

    const [navigation, setNavigation] = useState([]);
    const [isDrilling, setIsDrilling] = useState(false);
    const [isDrillManager, setIsDrillManager] = useState(false);

    const getTime = () => {
        if (profile.lastUpdated !== null) {
            var utcDate = profile.lastUpdated;
            var localDate = new Date(utcDate + 'Z');
            return (
                <>
                    {localDate.toLocaleDateString()}
                    &nbsp;
                    {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </>
            )
        }
        else {
            return (
                <>
                    N/A
                </>
            )
        }
    };

    useEffect(() => {
        checkDrillAuth()
    }, [])

    const checkDrillAuth = () => {
        CheckDrillingAuth()
            .then(res => {
                if (!res.success) {
                    setIsDrilling(false);
                }
                else if (res.success && res.message === 'Drilling Manager') {
                    setIsDrilling(true)
                    setIsDrillManager(true)
                }
                else if (res.success && res.message === 'Drilling User') {
                    setIsDrilling(true)
                    setIsDrillManager(false)
                }
            })
    }


    useEffect(() => {
        LoadProfile(parseInt(props.match.params.jobId))
    }, [props.match.params.jobId, props.location.pathname])

    const LoadProfile = (id) => {
        GetJobProfile(id)
            .then(res => {
                if (res.success) {
                    setPrevailingWage(res.prevailingWage)
                    setProfile(res.jobInfo)
                    setNavigation(res.navigation)
                }
            })
    }

    const reload = () => {
        LoadProfile(parseInt(props.match.params.jobId))
    }
    const history = useHistory();
    return (
        <>
            <div className="container-fluid ">
                <div className="divider my-2" />
                <div className="row mt-2">
                    {!isDrilling || isDrillManager ?
                        <div className="col text-right">
                            <CloneButton jobId={props.match.params.jobId} url={url} />
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                className="clickable ml-2"
                                onClick={() => history.push(`/Opportunities/${props.oppId}/${props.match.params.jobId}/Edit`)}
                            />
                        </div>
                        : null}
                </div>
                <div className="row">
                    <div className="col-4">
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Shipping Information
                        </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col">
                                        {profile.shippingLocation.locationName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {profile.shippingLocation.shippingAddress1}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {profile.shippingLocation.shippingAddress2}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {`
                                            ${profile.shippingLocation.shippingCity ? `${profile.shippingLocation.shippingCity},` : ""} 
                                            ${profile.shippingLocation.shippingState ? `${profile.shippingLocation.shippingState}` : ""} 
                                            ${profile.shippingLocation.shippingZip ? `${profile.shippingLocation.shippingZip}` : ""}
                                        `}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="col-4">

                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Job Identification
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col">
                                        <h4>{profile.canceled && "Cancelled -"} {profile.jobName}</h4>
                                    </div>
                                    <div className="col">
                                        {profile.jobNumber}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="col-4">
                        <ListGroup>
                            <ListGroup.Item className="enc-list">
                                Job Information
                            </ListGroup.Item>
                            <ListGroup.Item className="enc-list">
                                <div className="row">
                                    <div className="col">
                                        Classification: {profile.jobClassification}
                                    </div>
                                    <div className="col">
                                        Progress: {profile.jobStage} - {profile.jobStep}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Prevailing Wage: {prevailingWage ? "Yes" : "No"}
                                    </div>
                                    <div className="col">
                                        Last Updated: {getTime()}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>

                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        {profile.jobDescription}
                    </div>
                </div>
            </div>
            <div className="divider my-2" />
            <JobReminder jobId={jobId} />
            <Nav variant="tabs" className="my-2">
                {navigation.map((nav) => {
                    return (
                        <Nav.Item key={`StageNav-${nav.stageName}`}>
                            <Nav.Link eventKey={nav.navUrl} as={NavLink} to={`${url}/${nav.navUrl}`} disabled={nav.disabled}>{nav.stageName}</Nav.Link>
                        </Nav.Item>
                    )
                })
                }
            </Nav>
            <Switch>
                <Route path={`${url}/Scope`} render={() =>
                    <Stage1View reload={reload} jobId={jobId} url={url} />
                } />
                <Route path={`${url}/Approval`} render={() =>
                    <ApprovalMain jobId={jobId} url={url} />
                } />
                <Route path={`${url}/Installation`} render={(routeProps) =>
                    <InProgress stageName="Installation" jobId={jobId} url={routeProps.match.url} stepId={10} />
                } />
                <Route path={`${url}/OnRent`} render={(routeProps) =>
                    <InProgress stageName="On Rent" jobId={jobId} url={routeProps.match.url} stepId={11} />
                } />
                <Route path={`${url}/TearDown`} render={(routeProps) =>
                    <InProgress stageName="Tear Down" jobId={jobId} url={routeProps.match.url} stepId={12} />
                } />
                <Route path={`${url}/Budgeting`} render={(routeProps) =>
                    <Stage2Router reload={reload} jobId={jobId} url={url} {...routeProps} />
                } />
                <Route path={`${url}/closed`} render={(routeProps) =>
                    <ClosedJobView jobId={jobId} completedDate={profile.completedDate} completedBy={profile.completedBy} reload={reload}  />
                } />
                <Route path={`${url}/Clone`} render={() =>
                    <CloneJob jobId={jobId} />
                } />
                <Route path={`${url}`} render={() =>
                    <SelectScreen />
                } />
            </Switch>

            <div className="divider my-2" />
            <Switch>
                <Route path={`${url}/*/NewNote`} render={(routeProps) =>
                    <NewNote url={routeProps.match.url} jobId={profile.jobId} />
                } />
                <Route path={`${url}/*/EditNote/:noteId`} render={(routeProps) =>
                    <NewNote url={routeProps.match.url} jobId={profile.jobId} noteId={routeProps.match.params.noteId} />
                } />
                <Route path={`${url}`} render={(routeProps) =>
                    <NoteView filterId={profile.jobStepId} jobId={profile.jobId} url={`${url}/${stageUrl}`} />
                } />
            </Switch>
        </>
    )
}
