import React, { useState, useEffect } from 'react';
import { DropdownList } from 'react-widgets';
import { AllowedYardList } from '../../API/Inventory';
import { GetShopTicketList } from '../../API/ShopConversion';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [yards, setYards] = useState([]);
    const [selectedYard, setSelectedYard] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [closed, setClosed] = useState(false)

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [numberSort, setNumberSort] = useState(0);
    const [dateSort, setDateSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable"])



    useEffect(() => {
        AllowedYardList()
            .then(res => {
                setYards(res.data)
                if (res.data.length > 0) {
                    setSelectedYard(res.data[0])
                }
            })
    }, [])
    useEffect(() => {
        if (selectedYard) {
            GetShopTicketList(selectedYard.yardId, closed)
                .then(res => {
                    setTickets(res.data)
                })
        }
    }, [selectedYard, closed])



    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        tickets.forEach((ticket) => {
            
            if (ticket.ticketName === null || ticket.ticketName === undefined) {
                ticket.ticketName = `Unnamed Shop Ticket - ${ticket.shopTicketId}`
            }

            ticket.shopTicketId = ticket.shopTicketId.toString();
        })


        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            tickets.sort((a, b) => a.ticketName.localeCompare(b.ticketName));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            tickets.sort((a, b) => a.ticketName.localeCompare(b.ticketName));
            tickets.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setNumberSort(2)
            tickets.sort((a, b) => a.shopTicketId.localeCompare(b.shopTicketId));
        }
        else if (headerID === 1 && direction === 2) {
            setNumberSort(1)
            tickets.sort((a, b) => a.shopTicketId.localeCompare(b.shopTicketId));
            tickets.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setDateSort(2)
            tickets.sort((a, b) => a.date.localeCompare(b.date));
        }
        else if (headerID === 2 && direction === 2) {
            setDateSort(1)
            tickets.sort((a, b) => a.date.localeCompare(b.date));
            tickets.reverse();
        }
      
    }




    return (
        <div className="container">
            <div className="row mb-2">
                <div className="col-md-6">
                    <DropdownList
                        data={yards}
                        value={selectedYard}
                        textField="yardName"
                        onChange={e => setSelectedYard(e)}
                    />
                </div>
                <div className="col text-right">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={closed}
                            onChange={() => setClosed(!closed)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Closed Tickets
                    </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">Shop Ticket List</ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">

                        <div className="col-6">
                            Ticket Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                        </div>
                        <div className="col">
                            Ticket Number {numberSort === 0 || numberSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[1]} onClick={() => headerSort(1, 2)} />}
                        </div>
                        <div className="col">
                            Ticket Date {dateSort === 0 || dateSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[2]} onClick={() => headerSort(2, 2)} />}
                        </div>
                    </div>
                </ListGroup.Item>
                {tickets.map((m, i) => {
                    return (
                        <ListGroup.Item key={`ShopTicket-${i}`} className="click-list" as={Link} to={`${props.match.url}/${m.shopTicketId}`}>
                            <div className="row">
                                <div className="col-6">
                                    {m.ticketName ? m.ticketName : `Unnamed Shop Ticket - ${m.shopTicketId}`}
                                </div>
                                <div className="col">
                                    {m.shopTicketId}
                                </div>
                                <div className="col">
                                    {m.date}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
