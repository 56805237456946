import React, { useState, useEffect } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { GetSerializedItemAndStatusList, OutForRepairList } from '../../../API/AssetInventory';
import { Link } from 'react-router-dom';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default props => {
    const [items, setItems] = useState([])
    const [repair, setRepair] = useState(false)

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [serialSort, setSerialSort] = useState(0);
    const [statusSort, setStatusSort] = useState(0);
    const [locationSort, setLocationSort] = useState(0);
    const [activitySort, setActivitySort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable", "clickable"])

    useEffect(() => {
        if(repair){
            LoadRepairList();
        } else {
            LoadInventory();
        }
    }, [repair])

    const LoadInventory = () => {
        GetSerializedItemAndStatusList()
            .then(res => {
                setItems(res.data)
            })
    }

    const LoadRepairList = () => {
        OutForRepairList()
        .then(res => {
            setItems(res.data)
        })
    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)
    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)

        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            items.sort((a, b) => a.name.localeCompare(b.name));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            items.sort((a, b) => a.name.localeCompare(b.name));
            items.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setSerialSort(2)
            items.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
        }
        else if (headerID === 1 && direction === 2) {
            setSerialSort(1)
            items.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
            items.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setStatusSort(2)
            items.sort((a, b) => a.status.localeCompare(b.status));
        }
        else if (headerID === 2 && direction === 2) {
            setStatusSort(1)
            items.sort((a, b) => a.status.localeCompare(b.status));
            items.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setLocationSort(2)
            items.sort((a, b) => a.location.localeCompare(b.location));
        }
        else if (headerID === 3 && direction === 2) {
            setLocationSort(1)
            items.sort((a, b) => a.location.localeCompare(b.location));
            items.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 4 && direction === 1) {
            setActivitySort(2)
            items.sort((a, b) => a.lastActivity.localeCompare(b.lastActivity));
        }
        else if (headerID === 4 && direction === 2) {
            setActivitySort(1)
            items.sort((a, b) => a.lastActivity.localeCompare(b.lastActivity));
            items.reverse();
        }
       
    }


    return (
        
        <div className="container my-5">
            <div className="row">
                <div className="col">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={repair}
                            onChange={e => setRepair(!repair)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Assets Out for Repair
                </div>
                <div className="col text-right">
                    <Button as={Link} to={`${props.match.url}/CreateAsset`} className="enc-button">Create Asset</Button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Asset List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col">
                                    Serial Number {serialSort === 0 || serialSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                                <div className="col">
                                    Status {statusSort === 0 || statusSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                                </div>
                                <div className="col">
                                    Location {locationSort === 0 || locationSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(3, 2)} />}
                                </div>
                                <div className="col">
                                    Last Activity {activitySort === 0 || activitySort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[4]} onClick={() => headerSort(4, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[4]} onClick={() => headerSort(4, 2)} />}
                                </div>
                            </div>
                        </ListGroup.Item>
                        {items.map(i =>
                            <ListGroup.Item key={`Asset-${i.serializedItemId}`} as={Link} to={`${props.match.url}/${i.serializedItemId}`} className="click-list">
                                <div className="row">
                                    <div className="col">
                                        {i.name}
                                    </div>
                                    <div className="col">
                                        {i.serialNumber}
                                    </div>
                                    <div className="col">
                                        {i.status}
                                    </div>
                                    <div className="col">
                                        {i.location}
                                    </div>
                                    <div className="col">
                                        {i.lastActivity}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}
