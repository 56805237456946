import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Grid({ data = [], headers = [], headerName = "", clickRow = false, url="/", urlId="id" }) {
    const [dataList, setDataList] = useState(data)
    const [headerSort, setHeaderSort] = useState(0)
    const [sortBy, setSortBy] = useState({ sortKey: "", sortDesc: true, sortType: "basic" })
    const [classes, setClasses] = useState([]);

    const sortType = {
        BASIC: "basic",
        DATE: "date",
    };
    useEffect(() => {
        if (headers.length > 0 && headers[0].key) {
            setSortBy({ sortKey: headers[0].key, sortDesc: true, sortType: headers[0].sortType ? headers[0].sortType : sortType.BASIC })
        }
     
    }, [headers])

    useEffect(() => {
        if(data) {
            setDataList(data)
        }
    }, [data])

    useEffect(() => {
        let newArry = [];
        headers.forEach((header) => { 
            newArry.push("clickable")                    
        })
        setClasses(newArry)
    }, [headers])

 
    const handleSortBy = (headerID) => {
        let x = headerID

            setSortBy({ sortKey: headers[x].key, sortDesc: true, sortType: headers[x].sortType ? headers[x].sortType : sortType.BASIC })  
    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const handleSort = (headerID, direction) => {

        setClass(headerID)

        let s = [...dataList]

        handleSortBy(headerID)

        if (direction === 1) {
            setHeaderSort(2)
            sortBy.sortDesc = false;
        }
        if (direction === 2) {
            setHeaderSort(1)
            sortBy.sortDesc = true;
        }
        function compare(a, b) {
            var lhs = a[sortBy.sortKey];
            var rhs = b[sortBy.sortKey];
            if (sortBy.sortType == sortType.BASIC) {
                // No transformation
            }
            if (sortBy.sortType == sortType.DATE) {
                lhs = Date.parse(lhs);
                rhs = Date.parse(rhs);
            }
            if (lhs < rhs) {
                return -1;
            }
            if (lhs > rhs) {
                return 1;
            }
            return 0;
        }
        s = s.sort(compare);
        if (sortBy.sortDesc) {
            s.reverse()
        }
        setDataList(s)

    }

    return (
        <div className="container-fluid my-2">
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    {headerName ? headerName : "Data Grid"}
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        {headers.map((m, i) => {
                            return (
                                <div className={`col`} key={`header-${i}`}>
                                    {m.name} {headerSort === 0 || headerSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[i]} onClick={() => handleSort(i, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[i]} onClick={() => handleSort(i,2)} />}
                                </div>
                        
                            )
                        })}
                    </div>
                </ListGroup.Item>
                {dataList.map((m, i) => {
                    let AdditionalParams = {}
                    if(clickRow){
                        AdditionalParams["as"] = Link
                        AdditionalParams["to"] = `${url}/${m[urlId]}`
                        AdditionalParams["className"] = "click-list"
                    }
                    
                    return (
                        <ListGroup.Item key={`SDG-${i}`} {...AdditionalParams} > 
                            <div className="row">
                                {headers.map((h, hi) => {
                                    return (
                                        <div className="col" key={`SDG-DC-${hi}`}>
                                            {m[h.key]}
                                        </div>
                                    )
                                })}
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default Grid