import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { GetAccountJobs } from '../API/Account';
import { CheckEditOpportunity } from '../API/Opportunity';
import { JobSearch } from '../API/Jobs';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [jobs, setJobs] = useState([]);
    const [filter, setFilter] = useState("");
    const [editOpportunity, setEditOpportunity] = useState(false);


    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [oppSort, setOppSort] = useState(0);
    const [nameSort, setNameSort] = useState(0);
    const [classificationSort, setClassificationSort] = useState(0);
    const [jobNumSort, setJobNumSort] = useState(0);
    const [stageSort, setStageSort] = useState(0);
    const [dateSort, setDateSort] = useState(0);
    const [canceledJobs, setCanceledJobs] = useState(false);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable", "clickable", "clickable"])

    useEffect(() => {
        if (props.accountId) {
            GetAccountJobs(props.accountId, canceledJobs)
                .then(res => {
                    setJobs(res.data)
                })
        }
        CheckEditOpportunity()
        .then(res => {
            setEditOpportunity(res.success)
        })
    }, [props.accountId, canceledJobs])

    let history = useHistory();


 
    //const navigateToJob = (oppId, id, stageName) => {
    //    let stage = stageName.replace(/\s/g, '')
    //    const win = window.open(`/Opportunities/${oppId}/${id}/${stage}`, '_blank');
    //    win.focus();
    //    //history.push(`/Opportunities/${oppId}/${id}/${stage}`)
    //}

    const Search = () => {
        let payload = {
            canceled: canceledJobs
        }
        JobSearch(payload)
            .then(res => {
                setJobs(res.data)
            })
    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)

        jobs.forEach((job) => {
            if (job.lastUpdated === null || job.lastUpdated === undefined) {
                job.lastUpdated = new Date(null).toString();
            }
        })

        if (headerID === 0 && direction === 1) {
            setOppSort(2)
            jobs.sort((a, b) => a.opportunityName.localeCompare(b.opportunityName));
        }
        else if (headerID === 0 && direction === 2) {
            setOppSort(1)
            jobs.sort((a, b) => a.opportunityName.localeCompare(b.opportunityName));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setNameSort(2)
            jobs.sort((a, b) => a.jobName.localeCompare(b.jobName));
        }
        else if (headerID === 1 && direction === 2) {
            setNameSort(1)
            jobs.sort((a, b) => a.jobName.localeCompare(b.jobName));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setClassificationSort(2)
            jobs.sort((a, b) => a.jobClassification.localeCompare(b.jobClassification));
        }
        else if (headerID === 2 && direction === 2) {
            setClassificationSort(1)
            jobs.sort((a, b) => a.jobClassification.localeCompare(b.jobClassification));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setJobNumSort(2)
            jobs.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
        }
        else if (headerID === 3 && direction === 2) {
            setJobNumSort(1)
            jobs.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 4 && direction === 1) {
            setStageSort(2)
            jobs.sort((a, b) => a.jobStage.localeCompare(b.jobStage));
        }
        else if (headerID === 4 && direction === 2) {
            setStageSort(1)
            jobs.sort((a, b) => a.jobStage.localeCompare(b.jobStage));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 5 && direction === 1) {
            setDateSort(2)
            jobs.sort((a, b) => a.lastUpdated.localeCompare(b.lastUpdated));
        }
        else if (headerID === 5 && direction === 2) {
            setDateSort(1)
            jobs.sort((a, b) => a.lastUpdated.localeCompare(b.lastUpdated));
            jobs.reverse();
        }
    }




    return (
        <>
            <div className="container">
                <div>
                    <div className="row mt-2">
                        <div className="col">
                            {editOpportunity && <Button as={Link} to='/Opportunities/NewOpportunity' className="enc-button ml-2">New Opportunity</Button>}
                        </div>
                        <div className="col-6 text-right">
                            <input className="enc-input-100" placeholder="Job & Opportunity Name Filter" value={filter} onChange={e => setFilter(e.target.value)} />
                        </div>
                    </div>
                    <br></br>
                    <div className="col text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={canceledJobs}
                                onChange={() => setCanceledJobs(!canceledJobs)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                        &nbsp; &nbsp;Show Canceled Jobs

                    </div>
                    <ListGroup className="my-3">
                        <ListGroup.Item className="enc-data-list">Job List</ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">
                                <div className="col-lg-2">
                                    Opportunity {oppSort === 0 || oppSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col-lg-2">
                                    Job Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                                <div className="col-lg-2">
                                    Classification {classificationSort === 0 || classificationSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                                </div>
                                <div className="col-lg-2">
                                    Job Number {jobNumSort === 0 || jobNumSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(3, 2)} />}
                                </div>
                                <div className="col-lg-2">
                                    Stage {stageSort === 0 || stageSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[4]} onClick={() => headerSort(4, 1)} /> : <FontAwesomeIcon icon={faSortAmountUp} className={classes[4]} onClick={() => headerSort(4, 2)} />}
                                </div>
                                <div className="col-lg-2">
                                    Last Updated {dateSort === 0 || dateSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[5]} onClick={() => headerSort(5, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[5]} onClick={() => headerSort(5, 2)} />}
                                </div>
                            </div>
                        </ListGroup.Item>
                        {jobs.length === 0 && <ListGroup.Item>No Jobs To Display</ListGroup.Item>}
                        {jobs.filter(x => x.jobName.toLowerCase().search(filter.toLowerCase()) !== -1 || x.opportunityName?.toLowerCase().search(filter.toLowerCase()) !== -1).map(m => {
                            const getTime = () => {
                                if (m.lastUpdated !== null && m.lastUpdated !== "Wed Dec 31 1969 19:00:00 GMT-0500 (Eastern Standard Time)") {
                                    var utcDate = m.lastUpdated;
                                    var localDate = new Date(utcDate + 'Z');
                                    return (
                                        <>
                                            {localDate.toLocaleDateString()}
                                            &nbsp;
                                            {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            N/A
                                        </>
                                    )
                                }
                            };
                            return (
                                <ListGroup.Item key={`LGI-${m.jobId}`} as={Link} to={`/Opportunities/${m.opportunityId}/${m.jobId}/${m.jobStage}`} className="click-list">
                                  <div className="row">
                                        <div className="col-lg-2">
                                            {m.opportunityName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.canceled && "Cancelled -"} {m.jobName}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobClassification}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobNumber}
                                        </div>
                                        <div className="col-lg-2">
                                            {m.jobStage}
                                        </div>
                                        <div className="col-lg-2">
                                            {getTime()}
                                        </div>
                                    </div> 
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
