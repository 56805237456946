import React, { useState, useEffect } from 'react';
import { GetInventoryAudit } from '../../API/Inventory';
import { Button, ListGroup } from 'react-bootstrap';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [audit, setAudit] = useState([]);
    const [disable, setDisable] = useState(false);


    const [userSort, setUserSort] = useState(0);
    const [dateSort, setDateSort] = useState(0);
    const [locationSort, setLocationSort] = useState(0);
    const [invItemSort, setItemSort] = useState(0);
    const [amountSort, setAmountSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable", "clickable"])


    useEffect(() => {
        loadAuditRecords(0);
    }, []);

    const loadAuditRecords = (amount) => {
        GetInventoryAudit(amount)
            .then(res => {
                setAudit(res.data);
            });
    }

    const loadAllRecords = () => {
        setDisable(true);
        loadAuditRecords(1);
    }


    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)
    }

    const headerSort = (headerID, direction) => {

        setClass(headerID)

        audit.forEach((item) => {
            if (item.itemName === null || item.itemName === undefined) {
                item.itemName = ''
            }
            if (item.location === null || item.location === undefined) {
                item.location = ''
            }
        });

        if (headerID === 0 && direction === 1) {
            setUserSort(2)
            audit.sort((a, b) => a.fullName.localeCompare(b.fullName));
        }
        else if (headerID === 0 && direction === 2) {
            setUserSort(1)
            audit.sort((a, b) => a.fullName.localeCompare(b.fullName));
            audit.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setDateSort(2)
            audit.sort((a, b) => a.date.localeCompare(b.date));
        }
        else if (headerID === 1 && direction === 2) {
            setDateSort(1)
            audit.sort((a, b) => a.date.localeCompare(b.date));
            audit.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setLocationSort(2)
            audit.sort((a, b) => a.location.localeCompare(b.location));
        }
        else if (headerID === 2 && direction === 2) {
            setLocationSort(1)
            audit.sort((a, b) => a.location.localeCompare(b.location));
            audit.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setItemSort(2)
            audit.sort((a, b) => a.itemName.localeCompare(b.itemName));
        }
        else if (headerID === 3 && direction === 2) {
            setItemSort(1)
            audit.sort((a, b) => a.itemName.localeCompare(b.itemName));
            audit.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 4 && direction === 1) {
            setAmountSort(2)
            audit.sort((a, b) => a.amount.localeCompare(b.amount));
        }
        else if (headerID === 4 && direction === 2) {
            setAmountSort(1)
            audit.sort((a, b) => a.amount.localeCompare(b.amount));
            audit.reverse();
        }
       
    }




    return (
        <div className="container my-5">
            <div className="row mb-2">
                <div className="col text-right">
                    <Button className="enc-button" disabled={disable} onClick={loadAllRecords}>Load All Records</Button>
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    Inventory Audit
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col">
                            User {userSort === 0 || userSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 2)} />}
                        </div>
                        <div className="col">
                            Date {dateSort === 0 || dateSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                        </div>
                        <div className="col">
                            Location of Adjustment {locationSort === 0 || locationSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                        </div>
                    </div>
                    <div className="divider my-1" />
                    <div className="row">
                        <div className="col">
                            Inventory Item {invItemSort === 0 || invItemSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(3, 2)} />}
                        </div>
                        <div className="col">
                            Amount {amountSort === 0 || amountSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[4]} onClick={() => headerSort(4, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[4]} onClick={() => headerSort(4, 2)} />}
                        </div> 
                    </div>
                   
                </ListGroup.Item>
                {audit.map((a, i) => {
                    return (
                        <ListGroup.Item key={`audit-${i}`} className="enc-data-list">
                            <div className="row">
                                <div className="col">
                                    {a.fullName}
                                </div>
                                <div className="col">
                                    {a.date}
                                </div>
                                <div className="col">
                                    {a.location}
                                </div>
                            </div>
                            <div className="divider my-1" />

                            <div className="row">
                                <div className="col">
                                    {a.itemName}
                                </div>
                                <div className="col">
                                    {a.amount}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

        </div>
    )
}
