import React, { useEffect, useState } from 'react';
import { GetAdminBudgetEdit, DeleteBudgetCostLine, DeleteBudgetRevenueLine, DeleteBudgetHour, CreateBudgetCostLine, CreateBudgetRevenueLine, CreateBudgetHourLine } from '../API/Budget';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { DropdownList } from 'react-widgets';

export default props => {
    // Current Item State
    const [hours, setHours] = useState([])
    const [revenue, setRevenue] = useState([])
    const [cost, setCost] = useState([]) 
    const [categories, setCategories] = useState([])

    // Deletion State
    const [deleteRouter, setDeleteRouter] = useState(-1)
    const [deleteId, setDeleteId] = useState(0)
    const [confirmBox, setConfirmBox] = useState(false)
    const [itemName, setItemName] = useState('')

    // New State
    const [newCost, setNewCost] = useState({
        "category": 0,
        "number": "",
        "name": "",
        "labor": false
    })
    const [newRevenue, setNewRevenue] = useState({
        "number": "",
        "name": "",
    })
    const [newHour, setNewHour] = useState({
        "price": 0,
        "name": ""
    })

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    //budget
    const [costNumSort, setCostNumSort] = useState(0);
    const [costNameSort, setCostNameSort] = useState(0);
    const [costCatSort, setCostCatSort] = useState(0);
    const [costLaborSort, setCostLaborSort] = useState(0);
    //revenue
    const [revenueNumSort, setRevenueNumSort] = useState(0);
    const [revenueNameSort, setRevenueNameSort] = useState(0);
    //hour
    const [hourNameSort, setHourNameSort] = useState(0);
    const [hourPriceSort, setHourPriceSort] = useState(0);

    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable", "clickable", "clickable", "clickable", "clickable"])



    const setClass = (categoryID, headerID) => {
        let  hID = 0;
        let newArr = [...classes]
        switch (categoryID) {
            case 0:
                hID = headerID
                break;
            case 1:
                if (headerID === 0) {
                    hID = 4
                }
                else {
                    hID = 5
                }
                break;
            case 2:
                if (headerID === 0) {
                    hID = 6
                }
                else {
                    hID = 7
                }
                break;

            default:
                break;
     
        }

        classes.forEach((c, index) => {
            if (hID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)
    }



    const headerSort = (categoryID, headerID, direction) => {

        setClass(categoryID,headerID)

        switch (categoryID) {
            case 0:

                cost.forEach((c) => {
                    if (c.labor === undefined || c.labor === null) {
                        c.labor = false;
                    }
                })

                if (headerID === 0 && direction === 1) {
                    setCostNumSort(2)
                    cost.sort((a, b) => a.number.localeCompare(b.number));
                }
                else if (headerID === 0 && direction === 2) {
                    setCostNumSort(1)
                    cost.sort((a, b) => a.number.localeCompare(b.number));
                    cost.reverse();
                }
                //---------------------------------------------------------------------------
                if (headerID === 1 && direction === 1) {
                    setCostNameSort(2)
                    cost.sort((a, b) => a.name.localeCompare(b.name));
                }
                else if (headerID === 1 && direction === 2) {
                    setCostNameSort(1)
                    cost.sort((a, b) => a.name.localeCompare(b.name));
                    cost.reverse();
                }
                //---------------------------------------------------------------------------
                if (headerID === 2 && direction === 1) {
                    setCostCatSort(2)
                    cost.sort((a, b) => a.category.localeCompare(b.category));
                }
                else if (headerID === 2 && direction === 2) {
                    setCostCatSort(1)
                    cost.sort((a, b) => a.category.localeCompare(b.category));
                    cost.reverse();
                }
                //---------------------------------------------------------------------------
                if (headerID === 3 && direction === 1) {
                    setCostLaborSort(2)
                    cost.sort((a, b) => a.labor.toString().localeCompare(b.labor.toString()));
                }
                else if (headerID === 3 && direction === 2) {
                    setCostLaborSort(1)
                    cost.sort((a, b) => a.labor.toString().localeCompare(b.labor.toString()));
                    cost.reverse();
                }

                break;

            case 1:
                if (headerID === 0 && direction === 1) {
                    setRevenueNumSort(2)
                    revenue.sort((a, b) => a.number.localeCompare(b.number));
                }
                else if (headerID === 0 && direction === 2) {
                    setRevenueNumSort(1)
                    revenue.sort((a, b) => a.number.localeCompare(b.number));
                    revenue.reverse();
                }
                //---------------------------------------------------------------------------
                if (headerID === 1 && direction === 1) {
                    setRevenueNameSort(2)
                    revenue.sort((a, b) => a.name.localeCompare(b.name));
                }
                else if (headerID === 1 && direction === 2) {
                    setRevenueNameSort(1)
                    revenue.sort((a, b) => a.name.localeCompare(b.name));
                    revenue.reverse();
                }
                //---------------------------------------------------------------------------

                break;

            case 2:

                if (headerID === 0 && direction === 1) {
                    setHourNameSort(2)
                    hours.sort((a, b) => a.name.localeCompare(b.name));
                }
                else if (headerID === 0 && direction === 2) {
                    setHourNameSort(1)
                    hours.sort((a, b) => a.name.localeCompare(b.name));
                    hours.reverse();
                }
                //---------------------------------------------------------------------------
                if (headerID === 1 && direction === 1) {
                    setHourPriceSort(2)
                    hours.sort((a, b) => a.price.toString().localeCompare(b.price.toString()));
                }
                else if (headerID === 1 && direction === 2) {
                    setHourPriceSort(1)
                    hours.sort((a, b) => a.price.toString().localeCompare(b.price.toString()));
                    hours.reverse();
                }
                //---------------------------------------------------------------------------

                break;

            default:
                break;
        }

    }

    useEffect(() => {
        LoadData()
    }, [])

    const addCost = () => {
        CreateBudgetCostLine(newCost)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewCost({
                        "category": 0,
                        "number": "",
                        "name": "",
                        "labor": false
                    })
                }
            })
    }

    const addRevenue = () => {
        CreateBudgetRevenueLine(newRevenue)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewRevenue({
                        "number": "",
                        "name": "",
                    })
                }
            })
    }

    const addHour = () => {
        CreateBudgetHourLine(newHour)
            .then(res => {
                if (res.success) {
                    LoadData()
                    setNewHour({
                        "price": 0,
                        "name": ""
                    })
                }
            })
    }

    const editCost = (key, value) => {
        let s = { ...newCost }
        s[key] = value
        setNewCost(s)
    }

    const editRevenue = (key, value) => {
        let s = { ...newRevenue }
        s[key] = value
        setNewRevenue(s)
    }

    const editHour = (key, value) => {
        let s = { ...newHour }
        s[key] = value
        setNewHour(s)
    }

    const BoxData = (itemId, routeId, itemName) => {
        setDeleteId(itemId)
        setDeleteRouter(routeId)
        setItemName(itemName)
        setConfirmBox(true)
    }

    const LoadData = () => {
        GetAdminBudgetEdit()
            .then(res => {
                setHours(res.budgetHours)
                setRevenue(res.budgetRevenue)
                setCost(res.budgetCosts)
                setCategories(res.categories)
            })
    }

    const verifyNumberRevenue = (e) => {
        if (new RegExp('^([0-9]{0,5})$').test(e)) {
            editRevenue('number', e)
        }
    }

    const verifyNumberCost = (e) => {

        if (new RegExp('^([0-9]{0,5})$').test(e)) {
            editCost('number', e)
        }
    }

    const confirmDelete = () => {
        switch (deleteRouter) {
            case 1:
                DeleteBudgetCostLine(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            case 2:
                DeleteBudgetRevenueLine(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            case 3:
                DeleteBudgetHour(deleteId)
                    .then(res => {
                        if (res.success) {
                            LoadData()
                        }
                    })
                break;
            default:
                break;
        }
        setConfirmBox(false)
        setDeleteRouter(0)
    }


    return (
        <div className="container">
            <ListGroup className="mt-3">
                <ListGroup.Item className="enc-data-list">
                    Budgeting Costs
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">                   
                    <div className="row">
                        <div className="col-lg-2">
                            Number {costNumSort === 0 || costNumSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 0, 2)} />}
                        </div>
                        <div className="col-lg-6">
                            Name {costNameSort === 0 || costNameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(0, 1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(0, 1, 2)} />}
                        </div>
                        <div className="col-lg-2">
                            Category {costCatSort === 0 || costCatSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(0, 2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(0, 2, 2)} />}
                        </div>
                        <div className="col-lg-1">
                            Labor {costLaborSort === 0 || costLaborSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(0, 3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(0, 3, 2)} />}
                        </div>
                        <div className="col-lg-1">
                        </div>
                    </div>
                </ListGroup.Item>
                {cost.map((m, i) => (
                    <ListGroup.Item key={`costrow-${i}`} className="enc-budget-list">
                        <div className="row">
                            <div className="col-lg-2">
                                {m.number}
                            </div>
                            <div className="col-lg-6">
                                {m.name}
                            </div>
                            <div className="col-lg-2">
                                {m.category}
                            </div>
                            <div className="col-lg-1">
                                {m.labor && "Labor"}
                            </div>
                            <div className="col-lg-1 text-right">
                                <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => BoxData(m.budgetCostLineId, 1, m.name)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addCost() }}>
                        <div className="row mt-3">
                            <div className="col-lg-2">
                                <input
                                    required
                                    placeholder="Number"
                                    value={newCost.number}
                                    type='text'
                                    onChange={e => verifyNumberCost(e.target.value)}
                                    className="enc-input-100" />
                            </div>

                            <div className="col-lg-6">
                                <input
                                    placeholder="Name"
                                    value={newCost.name}
                                    className="enc-input-100"
                                    onChange={e => editCost('name', e.target.value)} />
                            </div>

                            <div className="col-lg-2">
                                <DropdownList
                                    data={categories}
                                    placeholder="Category"
                                    textField="name"
                                    value={newCost.category}
                                    valueField="budgetCategoryId"
                                    onChange={e => editCost('category', e.budgetCategoryId)} />
                            </div>

                            <div className="col-lg-2">
                                <input type="checkbox"
                                    checked={newCost.labor}
                                    onChange={e => editCost('labor', !newCost.labor)} /> Labor
                            </div>
                        </div>
                        <div className="col text-right pt-2  pb-2">
                            <Button type='submit' className="enc-button">Create Budget Cost Line</Button>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>


            <ListGroup className="mt-5">
                <ListGroup.Item className="enc-data-list">
                    Budget Revenue Lines
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-2">
                            Number {revenueNumSort === 0 || revenueNumSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[4]} onClick={() => headerSort(1, 0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[4]} onClick={() => headerSort(1, 0, 2)} />}
                        </div>
                        <div className="col-lg-9">
                            Name {revenueNameSort === 0 || revenueNameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[5]} onClick={() => headerSort(1, 1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[5]} onClick={() => headerSort(1, 1, 2)} />}
                        </div>
                        <div className="col-lg-1">
                        </div>
                    </div>
                </ListGroup.Item>
                {revenue.map((m, i) => (
                    <ListGroup.Item key={`rev-row-${i}`} className="enc-budget-list">
                        <div className="row">
                            <div className="col-lg-2">
                                {m.number}
                            </div>
                            <div className="col-lg-9">
                                {m.name}
                            </div>
                            <div className="col-lg-1 text-right">
                                <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => BoxData(m.budgetRevenueLineId, 2, m.name)} />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addRevenue() }}>
                        <div className="row mt-1">
                            <div className="col-lg-2">
                                <input
                                    required
                                    value={newRevenue.number}
                                    type='text'
                                    placeholder="Number"
                                    onChange={e => verifyNumberRevenue(e.target.value)}
                                    className="enc-input-100" />
                            </div>
                            <div className="col-lg-9">
                                <input
                                    required
                                    value={newRevenue.name}
                                    className="enc-input-100"
                                    placeholder="Name"
                                    type='text'
                                    onChange={e => editRevenue('name', e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-right pt-2 pb-2">
                                <Button type='submit' className="enc-button">Create Budget Revenue Line</Button>
                            </div>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>


            <ListGroup className="my-5">
                <ListGroup.Item className="enc-data-list">
                    Budgeting Hour - Calculator
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">
                        <div className="col-lg-6">
                            Name {hourNameSort === 0 || hourNameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[6]} onClick={() => headerSort(2, 0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[6]} onClick={() => headerSort(2, 0, 2)} />}
                        </div>
                        <div className="col-lg-3">
                            Price {hourPriceSort === 0 || hourPriceSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[7]} onClick={() => headerSort(2, 1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[7]} onClick={() => headerSort(2, 1, 2)} />}
                        </div>
                        <div className="col-lg-3 text-right">
                        </div>
                    </div>
                </ListGroup.Item>

                {hours.map((m, i) => (
                    <ListGroup.Item key={`budget-row-${i}`} className="enc-budget-list">

                        <div className="row">
                            <div className="col-lg-6">
                                {m.name}
                            </div>
                            <div className="col-lg-3">
                                ${m.price} / Hour
                        </div>
                            <div className="col-lg-3 text-right">
                                <FontAwesomeIcon icon={faTrash} onClick={() => BoxData(m.budgetCostHourPriceId, 3, m.name)} className="clickable" />
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <form action="#" onSubmit={(e) => { e.preventDefault(); addHour() }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <input
                                    required
                                    type='text'
                                    value={newHour.name}
                                    onChange={(e) => editHour('name', e.target.value)}
                                    placeholder="Name"
                                    className="enc-input-100" />
                            </div>
                            <div className="col-lg-3">
                                <input
                                    required
                                    type='number'
                                    value={newHour.price}
                                    step=".01"
                                    placeholder="Price"
                                    onChange={e => editHour('price', parseFloat(e.target.value))}
                                    className="enc-input-100" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-right py-2">
                                <Button type='submit' className="enc-button">Create Budget Hour Line</Button>
                            </div>
                        </div>
                    </form>
                </ListGroup.Item>
            </ListGroup>

            <Modal show={confirmBox} onHide={() => setConfirmBox(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove {itemName}? </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setConfirmBox(false)}>
                        Cancel
                </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Confirm
                </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
