import React, { useState, useEffect } from 'react';
import { GetBillOfLadingList } from '../../API/InventoryTransfer';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [historical, setHistorical] = useState(false)
    const [billOfLadings, setBillOfLadings] = useState([]);


    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [dateSort, setDateSort] = useState(0);
    const [originSort, setOriginSort] = useState(0);
    const [destSort, setDestSort] = useState(0);
    const [identifierSort, setIdentifierSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable"])



    useEffect(() => {
        GetBillOfLadingList(historical)
        .then(res=>{
            setBillOfLadings(res.data)
        })
    }, [historical])



    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        billOfLadings.forEach((bol) => {
            if (bol.identifier === null || bol.identifier === undefined) {
                bol.identifier = '';
            }
        })

        if (headerID === 0 && direction === 1) {
            setDateSort(2)
            billOfLadings.sort((a, b) => a.date.localeCompare(b.date));
        }
        else if (headerID === 0 && direction === 2) {
            setDateSort(1)
            billOfLadings.sort((a, b) => a.date.localeCompare(b.date));
            billOfLadings.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setOriginSort(2)
            billOfLadings.sort((a, b) => a.origin.localeCompare(b.origin));
        }
        else if (headerID === 1 && direction === 2) {
            setOriginSort(1)
            billOfLadings.sort((a, b) => a.origin.localeCompare(b.origin));
            billOfLadings.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setDestSort(2)
            billOfLadings.sort((a, b) => a.destination.localeCompare(b.destination));
        }
        else if (headerID === 2 && direction === 2) {
            setDestSort(1)
            billOfLadings.sort((a, b) => a.destination.localeCompare(b.destination));
            billOfLadings.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setIdentifierSort(2)
            billOfLadings.sort((a, b) => a.identifier.localeCompare(b.identifier));
        }
        else if (headerID === 3 && direction === 2) {
            setIdentifierSort(1)
            billOfLadings.sort((a, b) => a.identifier.localeCompare(b.identifier));
            billOfLadings.reverse();
        }
    }


    return (
        <div className="container">
            <div className="row mb-1">
                <div className="col text-right">
                    <div className="toggle-switch small-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            id="disabled"
                            checked={historical}
                            onChange={e => setHistorical(!historical)}
                        />
                        <label className="toggle-switch-label" htmlFor="disabled">
                            <span className={"toggle-switch-inner"} />
                            <span
                                className={"toggle-switch-switch"}
                            />
                        </label>
                    </div>
                    &nbsp; &nbsp;View Completed Shipments
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-data-list">
                    Bills of Lading List
                </ListGroup.Item>
                <ListGroup.Item className="enc-data-list">
                    <div className="row">

                        <div className="col-2">
                            Date {dateSort === 0 || dateSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                        </div>
                        <div className="col">
                            Origin {originSort === 0 || originSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[1]} onClick={() => headerSort(1, 2)} />}
                        </div>
                        <div className="col">
                            Destination {destSort === 0 || destSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[2]} onClick={() => headerSort(2, 2)} />}
                        </div>
                        <div className="col">
                            Identifier {identifierSort === 0 || identifierSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[3]} onClick={() => headerSort(3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[3]} onClick={() => headerSort(3, 2)} />}
                        </div>
                    </div>
                </ListGroup.Item>
                {billOfLadings.map((m, i) => {
                    return (
                        <ListGroup.Item key={`BOL-${i}`} as={Link} to={`${props.match.url}/${m.transferRequestId}`} className="click-list">
                            <div className="row">
                                <div className="col-2">
                                    {m.date}
                                </div>
                                <div className="col">
                                    {m.origin}
                                </div>
                                <div className="col">
                                    {m.destination}
                                </div>
                                <div className="col">
                                    {m.identifier}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>

    )
}
