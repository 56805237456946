import React, { useEffect, useState } from 'react';
import { GetJobList, CheckDrillingAuth } from '../API/Jobs';
import { useHistory, Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [jobs, setJobs] = useState([]);
    const [closedJobs, setClosedJobs] = useState(false);
    const [isDrilling, setIsDrilling] = useState(false);
    const [isDrillManager, setIsDrillManager] = useState(false);
    const oppId = props.match.params.oppId

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [classificationSort, setClassificationSort] = useState(0);
    const [jobNumSort, setJobNumSort] = useState(0);
    const [stageSort, setStageSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable", "clickable"])


    useEffect(() => {
        LoadJobs(parseInt(oppId), closedJobs)
    }, [oppId, closedJobs])

    useEffect(() => {
        checkDrillAuth()
    }, [])

    const checkDrillAuth = () => {
        CheckDrillingAuth()
            .then(res => {
                if (!res.success) {
                    setIsDrilling(false);
                }
                else if (res.success && res.message === 'Drilling Manager') {
                    setIsDrilling(true)
                    setIsDrillManager(true)
                }
                else if (res.success && res.message === 'Drilling User') {
                    setIsDrilling(true)
                    setIsDrillManager(false)
                }
            })
    }

    const LoadJobs = (id, closed) => {
        GetJobList(closed, -1, id)
            .then(res => {
                setJobs(res.data)
            })
    }
    //const history = useHistory();
    //const navigateToJob = (id, stageName) => {
    //    let stage = stageName.replace(/\s/g, '')
    //    history.push(`${props.match.url}/${id}/${stage}`)
    //}


    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            jobs.sort((a, b) => a.jobName.localeCompare(b.jobName));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            jobs.sort((a, b) => a.jobName.localeCompare(b.jobName));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setClassificationSort(2)
            jobs.sort((a, b) => a.jobClassification.localeCompare(b.jobClassification));
        }
        else if (headerID === 1 && direction === 2) {
            setClassificationSort(1)
            jobs.sort((a, b) => a.jobClassification.localeCompare(b.jobClassification));
            jobs.reverse();
        }

        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setJobNumSort(2)
            jobs.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
        }
        else if (headerID === 2 && direction === 2) {
            setJobNumSort(1)
            jobs.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
            jobs.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setStageSort(2)
            jobs.sort((a, b) => a.jobStage.localeCompare(b.jobStage));
        }
        else if (headerID === 3 && direction === 2) {
            setStageSort(1)
            jobs.sort((a, b) => a.jobStage.localeCompare(b.jobStage));
            jobs.reverse();
        }

    }




    return (
        <>
            <div className="container">
                <div className="row my-2">
                    <div className="col-8 text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={closedJobs}
                                onChange={() => setClosedJobs(!closedJobs)}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                        &nbsp; &nbsp;Show Canceled Jobs
                    </div>
                    <div className="col text-right">
                        {!isDrilling || isDrillManager ?
                            <Button className="enc-button" as={Link} to={`${props.match.url}/NewJob`}>New Job</Button>
                            : null}
                    </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        Job List
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">
                            <div className="col-lg-4">
                                Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 2)} />}
                            </div>
                            <div className="col-lg-3">
                                Classification {classificationSort === 0 || classificationSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                            </div>
                            <div className="col-lg-2">
                                Job Number {jobNumSort === 0 || jobNumSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                            </div>
                            <div className="col-lg-3">
                                Step {stageSort === 0 || stageSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(3, 1)} /> : <FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(3, 2)} />}
                            </div>
                        </div>
                    </ListGroup.Item>
                    {jobs.length === 0 && <ListGroup.Item>No Jobs to Display</ListGroup.Item>}
                    {jobs.map(m =>
                        <ListGroup.Item key={`LGI-${m.jobId}`} as={Link} to={props.match.url + "/" + m.jobId + "/" + m.jobStage} className="click-list">
                            <div className="row">
                                <div className="col-lg-4">
                                    {m.canceled && "Cancelled -"} {m.jobName}
                                </div>
                                <div className="col-lg-3">
                                    {m.jobClassification}
                                </div>
                                <div className="col-lg-2">
                                    {m.jobNumber}
                                </div>
                                <div className="col-lg-3">
                                    {m.jobStep}
                                </div>
                            </div>
                        </ListGroup.Item>)}
                </ListGroup>
            </div>
        </>
    )
}