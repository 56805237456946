import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetJobsWithInventoryAccountInformation } from '../../API/InventoryTransfer';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [jobList, setJobList] = useState([]);

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [numberSort, setNumberSort] = useState(0);
    const [accountSort, setAccountSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable"])


    useEffect(() => {
        GetJobsWithInventoryAccountInformation()
            .then(res => {
                setJobList(res.data)
            })
    }, [])



    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID) 

        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            jobList.sort((a, b) => a.jobName.localeCompare(b.jobName));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            jobList.sort((a, b) => a.jobName.localeCompare(b.jobName));
            jobList.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setNumberSort(2)
            jobList.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
        }
        else if (headerID === 1 && direction === 2) {
            setNumberSort(1)
            jobList.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber));
            jobList.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setAccountSort(2)
            jobList.sort((a, b) => a.accountName.localeCompare(b.accountName));
        }
        else if (headerID === 2 && direction === 2) {
            setAccountSort(1)
            jobList.sort((a, b) => a.accountName.localeCompare(b.accountName));
            jobList.reverse();
        }

    }



    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Job Inventory List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">

                                <div className="col">
                                    Job Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col">
                                   Job Number {numberSort === 0 || numberSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                                <div className="col">
                                    Account Name {accountSort === 0 || accountSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[2]} onClick={() => headerSort(2, 2)} />}
                                </div>
                            </div>
                        </ListGroup.Item>
                        {jobList.map((j, i) => {
                            return (
                                <ListGroup.Item key={`jobinv-${i}`} as={Link} to={`${props.match.url}/${j.jobId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {j.jobName}
                                        </div>
                                        <div className="col">
                                            {j.jobNumber}
                                        </div>
                                        <div className="col">
                                            {j.accountName}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}
