import React, { useState, useEffect } from 'react';

import { AuthRequest, CheckAuth } from '../API/Authentication';

import '../css/global.scss';

import LoginForm from './LoginForm';
import MainRouting from '../MainRouting';
import ResetPasswordLogin from './ResetPasswordLogin';
import LoadingOverlay from 'react-loading-overlay';

export default () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        VerifyAuth();
    }, []);
    const VerifyAuth = () => {
        CheckAuth()
        .then(res => {
            if (!res.success) {
                localStorage.removeItem("BAENCToken")
                sessionStorage.removeItem("BAENCToken")
            } else {
                setAuthenticated(res.success);
                setChangePassword(res.passwordChange)
                setLoading(false);
            }
        })
        .catch( err => {
            setAuthenticated(false)
            setErrorMessage(err.message)
            setLoading(false);
         })
        
    };
    const authenticate = (username, password) => {
        AuthRequest(username, password)
            .then(res => {
                if (res.success) {
                    sessionStorage.setItem("BAENCToken", res.token)
                    if(rememberMe){
                        localStorage.setItem("BAENCToken", res.token)
                    }
                    setAuthenticated(true);
                    setChangePassword(res.passwordChange);
                }
                setErrorMessage(res.message)
            })
            .catch(ex => {
                setAuthenticated(false);
                setErrorMessage(ex.message)
            })
    }
    const passwordChanged = () => {
        VerifyAuth()
    };
    const displayLogic = () => {
        if (changePassword) {
            return <ResetPasswordLogin changed={passwordChanged} force={true} />
        }
        else if (authenticated) {
            return <MainRouting logout={logout} />
        }
        else {
            return <LoginForm authenticate={authenticate} remember={remember} rememberValue={rememberMe} loginError={errorMessage}/>
        }
    };
    const logout = () => {
        localStorage.removeItem("BAENCToken");
        sessionStorage.removeItem("BAENCToken");
        VerifyAuth();
    };
    const remember = () => {
        setRememberMe(!rememberMe)
    }
    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading...'
            >
            {displayLogic()}
            </LoadingOverlay>
        </>
    );
}