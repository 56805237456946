import React, { useEffect, useState } from 'react';
import { GetOfficeList, DeleteOffice } from '../API/Office';
import { Card, ListGroup, ListGroupItem, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export default props => {

    const [officeName, setOfficeName] = useState('');
    const [show, setShow] = useState(false);
    const [officeID, setOfficeID] = useState(0)


    const [officeList, setOfficeList] = useState([]);
    useEffect(() => {
        LoadOfficeLocations();
    }, [])

    const history = useHistory();

    const checkNull = (val) => {
        return (val == null) ? "" : val
    }

    const LoadOfficeLocations = () => {
        GetOfficeList()
            .then(res => {
                if (res.success) {
                    setOfficeList(res.data)
                }
            })
    }

    const editOffice = (id) => {
        history.push(`/Admin/OfficeLocations/${id}/Edit`)
    }

    //const deleteOffice = (id) => {
    //    DeleteOffice(id)
    //    .then(res =>{
    //        LoadOfficeLocations()
    //    })
    //}

    const handleDelete = () => {

        DeleteOffice(officeID)
            .then(res => {
                handleCloseModal()
                LoadOfficeLocations()
            })

    }
    const resetValues = () => {
        setOfficeID(0)
        setOfficeName('')
    }

    const handleShowModal = (id, name) => {
        setShow(true)
        setOfficeID(id)
        setOfficeName(name)

    }

    const handleCloseModal = () => {
        setShow(false)
        resetValues()
    }


    return (
        <div className="container-fluid">
            <div className="row mb-3">
                <div className="col">
                    <input type="button" className="enc-button" onClick={() => history.push('/Admin/OfficeLocations/new/Edit')} value="Create Office" />
                </div>
            </div>
            <div className="row">
                {officeList.map(m => {
                    return (
                        <Card className="ml-3 mb-3" key={`ContactCard-${m.officeLocationId}`}>
                            <Card.Body style={{ width: '18rem' }} className="enc-cb">
                                <Card.Title>{m.name}</Card.Title>
                                <Card.Text>
                                    {checkNull(m.address)} <br />
                                    {checkNull(m.city) + ", " + checkNull(m.state) + " " + checkNull(m.zipCode)}
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroupItem>Phone: <Card.Link href={`tel:${m.phoneNumber}`}>{m.phoneNumber}</Card.Link></ListGroupItem>
                                <ListGroupItem>Fax: {m.faxNumber}</ListGroupItem>
                            </ListGroup>
                            <Card.Footer >
                                <div className="row justify-content-around">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faPencilAlt} className="clickable" onClick={()=>editOffice(m.officeLocationId)} />
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => handleShowModal(m.officeLocationId, m.name)} />
                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    )
                })}
            </div>
            <Modal show={show} onHide={handleCloseModal} aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove the {officeName} office location? </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
