import React, { useState, useEffect } from 'react';
import CallIntakeView from './CallIntakeView';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Combobox, DropdownList } from 'react-widgets';
import { GetAccountList, GetAccountLocations, GetAccountContacts } from '../API/Account';
import { GetOfficeList } from '../API/Office';
import { useHistory } from 'react-router-dom';
import { GetOpportunityProfile, SaveOpportunity, CloseOpportunity, OpenOpportunity, CheckDrillingAuth  } from '../API/Opportunity';
import ConfirmDelete from '../Components/ConfirmDelete';

import stateList from '../Components/stateList';


export default props => {
    const [confirmClose, setConfirmClose] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isDrilling, setIsDrilling] = useState(false);
    const [isDrillManager, setIsDrillManager] = useState(false);
    /// Start 
    const [error, setError] = useState("");
    // Lists for Dropdowns
    const [billingLocationList, setBillingLocationList] = useState([]);
    const [shippingLocationList, setShippingLocationList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    /// Objects that build an opportunity.
    const [billingLocation, setBillingLocation] = useState({
        "accountLocationId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    });

    const [shippingLocation, setShippingLocation] = useState({
        "accountLocationId": 0,
        "locationName": "",
        "billingAddress1": "",
        "billingAddress2": "",
        "billingCity": "",
        "billingState": "",
        "billingZip": "",
        "shippingAddress1": "",
        "shippingAddress2": "",
        "shippingCity": "",
        "shippingState": "",
        "shippingZip": "",
        "enabled": true
    })
    const [contact, setContact] = useState({
        accountContactId: 0,
        fullName: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        enabled: true
    });
    const [opportunity, setOpportunity] = useState({
        "accountId": 0,
        "accountName": "",
        "opportunityId": 0,
        "opportunityName": "",
        "description": "",
        "duration": "",
        "gpsLatitude": "",
        "gpsLongitude": "",
        "lsd": "",
        "typeOfSite": "",
        "closed": false,
        "isDrilling":  false,
        "officeId": 0,
    });

    

    useEffect(() => {
        checkDrillAuth()
    }, [])

    const checkDrillAuth = () => {
        CheckDrillingAuth()
            .then(res => {
                if (!res.success) {
                    setIsDrilling(false);
                }
                else if (res.success && res.message === 'Drilling Manager') {
                    setIsDrillManager(true)
                    changeOpp('isDrilling', true)
                }
           
            })
    }

    

    // Loading of Data
    useEffect(() => {
        if (opportunity.accountId !== 0) {
            loadLocations(parseInt(opportunity.accountId))
            loadContacts(parseInt(opportunity.accountId))
            clearLocation();
            clearContact();
        } else {
            setBillingLocationList([])
            setShippingLocationList([])
            setContactsList([])
        }
    }, [opportunity.accountId])

    useEffect(() => {
        loadLists()
        if (props.oppId) {
            loadOpportunityInformation(parseInt(props.oppId))
        }
    }, [props.oppId])


    // Location Methods
    const changeLocationComboBox = (e) => {
        let s = { ...shippingLocation };
        if (typeof (e) === 'string') {
            s.locationName = e
            setShippingLocation(s)
        } else {
            setShippingLocation(e)
        }
    }

    const loadLocations = (id) => {
        GetAccountLocations(id, true)
            .then(res => {
                setShippingLocationList(res.data)
            })
        GetAccountLocations(id, false)
            .then(res => {
                setBillingLocationList(res.data)
            })
    }

    const changeLocation = (key, value) => {
        let s = { ...shippingLocation }
        if (s.accountLocationId !== 0) {
            if (window.confirm("Changing this field will create a new location")) {
                s.accountLocationId = 0;
                s.locationName = ""
                s[key] = value;
                setShippingLocation(s)
            }
        } else {
            s[key] = value;
            setShippingLocation(s)
        }
    }

    const cancelOpportunity = () => {
        CloseOpportunity(opportunity.opportunityId)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${res.id}`)
                }
            })
    }

    const openOpportunity = () => {
        OpenOpportunity(opportunity.opportunityId)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${res.id}`)
                }
            })
    }

    // Clear Locations
    const clearLocation = () => {
        setShippingLocation({
            "accountLocationId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true
        })
    }

    const clearBillingLocation = () => {
        setBillingLocation({
            "accountLocationId": 0,
            "locationName": "",
            "billingAddress1": "",
            "billingAddress2": "",
            "billingCity": "",
            "billingState": "",
            "billingZip": "",
            "shippingAddress1": "",
            "shippingAddress2": "",
            "shippingCity": "",
            "shippingState": "",
            "shippingZip": "",
            "enabled": true
        })
    }

    // end

    // Contact Methods

    const changeContact = (key, value) => {
        let s = { ...contact }
        if (s.accountContactId !== 0) {
            if (window.confirm("Changing this field will create a new contact")) {
                s.accountContactId = 0;
                s[key] = value;
                setContact(s)
            }
        } else {
            s[key] = value
            setContact(s)
        }
    }

    const loadContacts = (id) => {
        GetAccountContacts(id)
            .then(res => {
                let payload = [...res.data]
                if (payload.length > 0) {
                    payload.unshift({
                        accountContactId: 0,
                        fullName: "Find Existing Contact"
                    })
                }
                setContactsList(payload)
            })
    }

    const autoFill = (e) => {
        if (e.accountContactId !== 0) {
            setContact(e)
        }
        else {
            clearContact();
        }
    }

    const clearContact = () => {
        setContact({
            accountContactId: 0,
            fullName: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            enabled: true
        })
    }

    // end

    // Opportunity Methods
    const loadOpportunityInformation = (id) => {
        GetOpportunityProfile(id)
            .then(res => {
                if (res.success) {
                    setOpportunity({ ...res.opportunity, accountName: res.account.accountName })
                    //setAccount(res.account)
                    setBillingLocation(res.billingLocation)
                    setShippingLocation(res.shippingLocation)
                    setContact(res.contact)
                }
            })
    }

    const findAccount = (obj) => {
        let state = { ...opportunity }
        state.accountId = obj.accountId
        state.accountName = obj.accountName
        setOpportunity(state)
    }

    const loadLists = () => {
        GetAccountList(true)
            .then(res => {
                setAccountList(res.data)
            })
        GetOfficeList()
            .then(res => {
                setOfficeList(res.data)
            })
    }

    const changeAccount = (e) => {
        let state = { ...opportunity }
        if (typeof (e) === 'string') {
            if (state.accountId !== 0) {
                if (window.confirm("Changing this field will clear preloaded location and contact info.")) {
                    if (contact.accountContactId !== 0) {
                        clearContact();
                    }
                    if (shippingLocation.accountLocationId !== 0) {
                        clearLocation();
                        clearBillingLocation();
                    }
                } else {
                    return;
                }
            }
            state.accountName = e
            state.accountId = 0

        } else {
            state.accountName = e.accountName;
            state.accountId = e.accountId;
        }
        setOpportunity(state)
    }

    const changeOpp = (key, val) => {
        let state = { ...opportunity }
        state[key] = val;
        setOpportunity(state)
    }


    // Save
    const history = useHistory();
    const saveOpportunity = () => {
        let payload = { ...opportunity }
        if (opportunity.officeId === 0) {
            setError("Please select an office location.")
            return;
        }
        if (props.callId) {
            payload.callId = parseInt(props.callId)
        }
        payload.contact = contact
        payload.shippingLocation = shippingLocation
        payload.billingLocation = billingLocation
        SaveOpportunity(payload)
            .then(res => {
                if (res.success) {
                    history.push(`/Opportunities/${res.id}`)
                }
            })
    }

    const AccountBlur = () => {
        let accountName = opportunity.accountName
        let account = accountList.filter(account => account.accountName === accountName)
        if (account.length === 1) {
            let s = { ...opportunity }
            s.accountId = account[0].accountId
            s.accountName = account[0].accountName
            setOpportunity(s)
        }
    }

    const LocationBlur = () => {
        let locationName = shippingLocation.locationName
        let ListLocation = shippingLocationList.filter(location => location.locationName === locationName)
        if (ListLocation.length === 1) {
            setShippingLocation(ListLocation[0])
        }
    }

    return (

        <div className="container-fluid">

            <form action="#" onSubmit={(e) => { e.preventDefault(); saveOpportunity() }}>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col">
                            <h3>New Opportunity</h3>
                        </div>
                    </div>
                    {props.callId &&
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Call Details
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body><CallIntakeView url={props.url} findAccount={findAccount} callId={props.callId} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>}
                    <div className="row mt-2">
                        <div className="col-2">
                            <strong>Account Name:</strong>
                        </div>
                        <div className="col">
                            <Combobox
                                inputProps={{ required: true, onBlur: () => AccountBlur() }}
                                filter="contains" value={opportunity.accountName}
                                valueField='accountName' data={accountList}
                                textField='accountName' onChange={changeAccount}
                                placeholder="Example Company" />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-2">
                            <strong>Opportunity Name:</strong>
                        </div>
                        <div className="col">
                            <input required type='text' value={opportunity.opportunityName} onChange={(e) => { changeOpp('opportunityName', e.target.value) }} className="enc-input-100" placeholder="Example Opportunity" />
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row mt-3">
                        <div className="col-2">
                            <strong>Billing Location:</strong>
                        </div>
                        <div className="col">
                            <DropdownList
                                inputProps={{ required: true }}
                                placeholder="Location Name"
                                textField='locationName'
                                valueField='locationName'
                                value={billingLocation.locationName}
                                data={billingLocationList}
                                onChange={(e) => setBillingLocation(e)}
                            />
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row mb-1">
                        <div className="col-2">
                            <strong>Contact:</strong>
                        </div>
                        <div className="col">
                            <DropdownList
                                placeholder="Find Existing Contact"
                                defaultValue={"Find Existing Contact"}
                                onChange={(e) => autoFill(e)}
                                filter="contains"
                                data={contactsList}
                                value={contact}
                                textField="fullName" />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>First Name</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="firstName"
                                value={contact.firstName}
                                placeholder="First Name"
                                onChange={e => changeContact('firstName', e.target.value)} />
                        </div>
                        <div className="col">
                            <small>Last Name</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="lastName"
                                value={contact.lastName}
                                placeholder="Last Name"
                                onChange={e => changeContact('lastName', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <small>Phone</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="phoneNumber"
                                value={contact.phoneNumber}
                                placeholder="Phone Number"
                                onChange={e => changeContact('phoneNumber', e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>Email</small>
                            <input type="text"
                                required
                                className="enc-input-100"
                                name="email"
                                value={contact.email}
                                placeholder="Email" onChange={e => changeContact('email', e.target.value)} />
                        </div>
                    </div>

                    <div className="divider my-2" />


                    <div className="row">
                        <div className="col">
                            <h3>Shipping Location</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Combobox
                                inputProps={{ required: true, onBlur: () => LocationBlur() }}
                                placeholder="Location Name"
                                textField='locationName'
                                valueField='locationName'
                                value={shippingLocation.locationName}
                                data={shippingLocationList}
                                onChange={(e) => changeLocationComboBox(e)}
                            />
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <div className="row mt-3 mb-1">
                        <div className="col">
                            <small>Job Shipping Address</small>
                            <input type="text"
                                className="enc-input-100"
                                name="shippingAddress1"
                                value={shippingLocation.shippingAddress1}
                                placeholder="Shipping Address"
                                onChange={e => changeLocation('shippingAddress1', e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <input type="text"
                                className="enc-input-100"
                                name="shippingAddress2"
                                value={shippingLocation.shippingAddress2}
                                onChange={e => changeLocation('shippingAddress2', e.target.value)} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <small>City</small>
                            <input type="text"
                                className="enc-input-100"
                                name="shippingCity"
                                value={shippingLocation.shippingCity}
                                placeholder="City"
                                onChange={e => changeLocation('shippingCity', e.target.value)} />
                        </div>
                        <div className="col">
                            <small>State</small>
                            <DropdownList
                                filter="contains"
                                value={shippingLocation.shippingState}
                                onChange={e => changeLocation('shippingState', e)}
                                data={stateList}
                            />
                        </div>
                        <div className="col">
                            <small>Zip Code</small>
                            <input type="text"
                                className="enc-input-100"
                                name="shippingZip"
                                value={shippingLocation.shippingZip}
                                placeholder="Zip Code"
                                onChange={e => changeLocation('shippingZip', e.target.value)} />
                        </div>
                    </div>

                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            <h3>Site Information</h3>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-lg-6">
                            <small>Site Type</small>
                            <input type='text' value={opportunity.typeOfSite} onChange={(e) => { changeOpp('typeOfSite', e.target.value) }} className="enc-input-100" placeholder="Site Type" />
                            <small>LSD</small>
                            <input type='text' value={opportunity.lsd} onChange={(e) => { changeOpp('lsd', e.target.value) }} className="enc-input-100" placeholder="LSD" />
                        </div>
                        <div className="col-lg-6">
                            <small>GPS Latitude</small>
                            <input type='text' value={opportunity.gpsLatitude} onChange={(e) => { changeOpp('gpsLatitude', e.target.value) }} className="enc-input-100" placeholder="GPS Latitude" />
                            <small>GPS Longitude</small>
                            <input type='text' value={opportunity.gpsLongitude} onChange={(e) => { changeOpp('gpsLongitude', e.target.value) }} className="enc-input-100" placeholder="GPS Longitude" />
                        </div>
                        <br></br>
                        {isDrillManager ? <div className="col-lg-6">
                            <small>Drilling Site </small>
                            <input
                                type="checkbox"
                                id="disabled"
                                checked={true}
                             
                            />
                        </div> :
                            <div className="col-lg-6">
                                <small>Drilling Site </small>
                                <input
                                    type="checkbox"
                                    id="disabled"
                                    checked={opportunity.isDrilling}
                                    onChange={(e) => changeOpp('isDrilling', !opportunity.isDrilling)}
                                />
                            </div>
                        }
                    </div>
                    <div className="divider my-2" />
                    <div className="row">
                        <div className="col">
                            <h3>BAENC Office</h3>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <small>BAENC Regional Office</small>
                            <DropdownList data={officeList} placeholder="Regional Office Selection" textField='name' value={opportunity.officeId} valueField='officeLocationId' onChange={(e) => changeOpp('officeId', e.officeLocationId)} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <small>Additional Opportunity Details</small>
                            <textarea value={opportunity.description} onChange={(e) => { changeOpp('description', e.target.value) }} className="enc-textarea" placeholder="Description" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-8">
                            <span style={{ color: 'red', weight: 'bold' }}>{error}</span>
                        </div>
                        <div className="col text-right">
                            <ConfirmDelete
                                show={confirmClose}
                                setShow={setConfirmClose}
                                action={cancelOpportunity}
                                message="Confirm you would like to close opportunity."
                            />
                            <ConfirmDelete
                                show={confirmOpen}
                                setShow={setConfirmOpen}
                                action={openOpportunity}
                                message="Confirm you would like to re-open opportunity."
                            />
                            {opportunity.opportunityId !== 0 && !opportunity.closed &&
                                <Button
                                    variant="danger"
                                    className="mr-2"
                                    onClick={() => { setConfirmClose(true) }}>
                                    Close Opportunity
                            </Button>}
                            {opportunity.opportunityId !== 0 && opportunity.closed &&
                                <Button
                                    variant="danger"
                                    className="mr-2"
                                    onClick={() => { setConfirmOpen(true) }}>
                                    Open Opportunity
                            </Button>}
                            {props.jobId && <Button onClick={() => history.goBack()} className="enc-button mr-2">Back</Button>}
                            <Button type='submit' className="enc-button">Save</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div >

    )
}
