import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { YardList } from '../API/Inventory';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [yards, setYards] = useState([])

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [officeSort, setOfficeSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable"])


    useEffect(() => {
        LoadYardList();
    }, [])

    const LoadYardList = () => {
        YardList()
            .then(res => {
                setYards(res.data)
            })
    }


    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            yards.sort((a, b) => a.yardName.localeCompare(b.yardName));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            yards.sort((a, b) => a.yardName.localeCompare(b.yardName));
            yards.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setOfficeSort(2)
            yards.sort((a, b) => a.officeName.localeCompare(b.officeName));
        }
        else if (headerID === 1 && direction === 2) {
            setOfficeSort(1)
            yards.sort((a, b) => a.officeName.localeCompare(b.officeName));
            yards.reverse();
        }
     
    }



    return (
        <>
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-data-list">
                            Yard List
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">

                                <div className="col">
                                    Yard Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col">
                                    Office Name {officeSort === 0 || officeSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                               
                            </div>
                        </ListGroup.Item>
                        {yards.map(y => {
                            return (
                                <ListGroup.Item as={Link} key={`${y.yardId}-yard`} to={`${props.match.url}/${y.yardId}`} className="click-list">
                                    <div className="row">
                                        <div className="col">
                                            {y.yardName}
                                        </div>
                                        <div className="col">
                                            {y.officeName}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
