import React, { useState, useEffect } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DeleteBulkItem, GetBulkItemList } from '../../../API/InventoryBulk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [items, setItems] = useState([])

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [nameSort, setNameSort] = useState(0);
    const [catSort, setCatSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable"])

    useEffect(() => {
        LoadBulkItemList();
    }, [])

    const LoadBulkItemList = () => {
        GetBulkItemList()
            .then(res => [
                setItems(res.data)
            ])
    }

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [stagedForDelete, setStagedForDelete] = useState(0);


    const openConfirmDelete = (id) => {
        setStagedForDelete(id);
        setConfirmDelete(true);
    }

    const deleteCategory = () => {
        let id = items[stagedForDelete].inventoryBulkItemId
        DeleteBulkItem(id)
            .then(() => {
                LoadBulkItemList()
                setConfirmDelete(false);
            })
    }


    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)
    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        if (headerID === 0 && direction === 1) {
            setNameSort(2)
            items.sort((a, b) => a.bulkItemName.localeCompare(b.bulkItemName));
        }
        else if (headerID === 0 && direction === 2) {
            setNameSort(1)
            items.sort((a, b) => a.bulkItemName.localeCompare(b.bulkItemName));
            items.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setCatSort(2)
            items.sort((a, b) => a.category.localeCompare(b.category));
        }
        else if (headerID === 1 && direction === 2) {
            setCatSort(1)
            items.sort((a, b) => a.category.localeCompare(b.category));
            items.reverse();
        }


    }


    return (

        <div className="container">
            <div className="row">
                <div className="col">
                    <ListGroup>
                        <ListGroup.Item className="enc-list">
                            <div className="row">
                                <div className="col">
                                    Bulk Items
                                </div>
                                <div className="col text-right">
                                    <Button as={Link} to={`${props.match.url}/new`} className="enc-button">
                                        New Item
                                    </Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="enc-data-list">
                            <div className="row">

                                <div className="col-6">
                                    Name {nameSort === 0 || nameSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col-6">
                                    Category {catSort === 0 || catSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>

                            </div>
                        </ListGroup.Item>
                        {items.map((m, i) =>
                            <ListGroup.Item key={`BulkItem-${i}`} as={Link} to={`${props.match.url}/${m.inventoryBulkItemId}`} className="click-list">
                                <div className="row">

                                    <div className="col-11">
                                       
                                            <div className="row">
                                                <div className="col-6">
                                                    {m.bulkItemName}
                                                </div>
                                                <div className="col-5">
                                                    {m.category}
                                                </div>
                                            </div>
                                       
                                    </div>

                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => openConfirmDelete(i)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
            <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will delete this Bulk Item. Please confirm you would like to take this action.
                    </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteCategory} >
                        Confirm
                        </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
