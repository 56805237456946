import React, { useEffect, useState } from 'react';
import { GetEmployeeList } from '../API/Employee';
import { useHistory, Link } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [EmployeeList, setEmployeeList] = useState([]);

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [fnSort, setFNSort] = useState(0);
    const [lnSort, setLNSort] = useState(0);
    const [phoneSort, setPhoneSort] = useState(0);
    const [emailSort, setEmailSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable","clickable"])

    
    useEffect(() => {
        loadEmployeess();
    }, [])

    const loadEmployeess = () => {
        GetEmployeeList({})
            .then(res => {
                setEmployeeList(res.data)
            })
    }

    const history = useHistory();
    const navigateToEmployee = (eId) => {
        history.push(`/Employees/${eId}`)
    }


    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }


    const headerSort = (headerID, direction) => {

        setClass(headerID)


        if (headerID === 0 && direction === 1) {
            setFNSort(2)
            EmployeeList.sort((a, b) => a.firstName.localeCompare(b.firstName));
        }
        else if (headerID === 0 && direction === 2) {
            setFNSort(1)
            EmployeeList.sort((a, b) => a.firstName.localeCompare(b.firstName));
            EmployeeList.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setLNSort(2)
            EmployeeList.sort((a, b) => a.lastName.localeCompare(b.lastName));
        }
        else if (headerID === 1 && direction === 2) {
            setLNSort(1)
            EmployeeList.sort((a, b) => a.lastName.localeCompare(b.lastName));
            EmployeeList.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setPhoneSort(2)
            EmployeeList.sort((a, b) => a.phoneNumber.localeCompare(b.phoneNumber));
        }
        else if (headerID === 2 && direction === 2) {
            setPhoneSort(1)
            EmployeeList.sort((a, b) => a.phoneNumber.localeCompare(b.phoneNumber));
            EmployeeList.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 3 && direction === 1) {
            setEmailSort(2)
            EmployeeList.sort((a, b) => a.email.localeCompare(b.email));
        }
        else if (headerID === 3 && direction === 2) {
            setEmailSort(1)
            EmployeeList.sort((a, b) => a.email.localeCompare(b.email));
            EmployeeList.reverse();
        }
    }




    return (
        <div className="container pt-4">
            <div className="row">
                <div className="col pb-1">
                    <Button className="enc-button" as={Link} to={`/Employees/new`}>New Employee</Button>
                </div>
            </div>
            <div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">Employee List</ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">

                            <div className="col">
                                First Name {fnSort === 0 || fnSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                            </div>
                            <div className="col">
                                Last Name {lnSort === 0 || lnSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                            </div>
                            <div className="col">
                                Phone {phoneSort === 0 || phoneSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                            </div>
                            <div className="col">
                               Email {emailSort === 0 || emailSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[3]} onClick={() => headerSort(3, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[3]} onClick={() => headerSort(3, 2)} />}
                            </div>
                        </div>
                    </ListGroup.Item>

                    {EmployeeList.map((e, i) =>
                        <ListGroup.Item key={`Employee-${i}`} onClick={() => navigateToEmployee(e.employeeId)} className="click-list">
                            <div className="row">
                                <div className="col">
                                    {e.firstName}
                                </div>
                                <div className="col">
                                    {e.lastName}
                                </div>
                                <div className="col">
                                    {e.phoneNumber}
                                </div>
                                <div className="col">
                                    {e.email}
                                </div>
                            </div>
                            <div className="row">
                                
                            </div>
                        </ListGroup.Item>)}
                </ListGroup>
            </div>
        </div>
    )
}
