import React, { useEffect, useState } from 'react';
import { GetCallList } from '../API/Call';
import { ListGroup, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
    const [calls, setCalls] = useState([]);
    const [viewActive, setViewActive] = useState(true);

    //Sorting values: 0 = default, 1 = decending, 2 = ascending 
    const [dateSort, setDateSort] = useState(0);
    const [companySort, setCompanySort] = useState(0);
    const [callerSort, setCallerSort] = useState(0);
    const [classes, setClasses] = useState(["clickable", "clickable", "clickable",])


    useEffect(() => {
        loadCalls(true)
    }, [])

    const loadCalls = (active) => {
        GetCallList(active)
            .then(res => { setCalls(res.data) })
    }

    const GenerateLink = (callInfo) => {
        if (callInfo.active) {
            return `${props.match.url}/${callInfo.callId}`
        } else {
            return `${props.url}/ClosedCall/${callInfo.callId}`
        }
    }

    useEffect(() => {
        loadCalls(viewActive)
    }, [viewActive])

    const handleChange = () => {
        setViewActive(!viewActive)
    }

    const setClass = (headerID) => {
        let newArr = [...classes]

        classes.forEach((c, index) => {
            if (headerID === index) {
                newArr[index] = "clickable fa-icon-toggle-on"
            }
            else {
                newArr[index] = "clickable"
            }
        })

        setClasses(newArr)

    }

    const headerSort = (headerID, direction) => {

        setClass(headerID)
       

        if (headerID === 0 && direction === 1) {
            setDateSort(2)
            calls.sort((a, b) => a.callTime.localeCompare(b.callTime));
        }
        else if (headerID === 0 && direction === 2) {
            setDateSort(1)
            calls.sort((a, b) => a.callTime.localeCompare(b.callTime));
            calls.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 1 && direction === 1) {
            setCompanySort(2)
            calls.sort((a, b) => a.companyName.localeCompare(b.companyName));
        }
        else if (headerID === 1 && direction === 2) {
            setCompanySort(1)
            calls.sort((a, b) => a.companyName.localeCompare(b.companyName));
            calls.reverse();
        }
        //---------------------------------------------------------------------------
        if (headerID === 2 && direction === 1) {
            setCallerSort(2)
            calls.sort((a, b) => a.callerName.localeCompare(b.callerName));
        }
        else if (headerID === 2 && direction === 2) {
            setCallerSort(1)
            calls.sort((a, b) => a.callerName.localeCompare(b.callerName));
            calls.reverse();
        }
    }




    return (
        <>
            <Nav variant="tabs" className="my-2" defaultActiveKey={2}>
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={1} to={`/Opportunities`}>Job Opportunities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={2} as={NavLink} to={`${props.match.url}/Call`} disabled>Call Opportunities</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="container">
                <div className="row mb-1">
                    <div className="col text-right">
                        <div className="toggle-switch small-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                id="disabled"
                                checked={viewActive}
                                onChange={handleChange}
                            />
                            <label className="toggle-switch-label" htmlFor="disabled">
                                <span className={"toggle-switch-inner"} />
                                <span
                                    className={"toggle-switch-switch"}
                                />
                            </label>
                        </div>
                    &nbsp; &nbsp;Show Open Calls
                </div>
                </div>
                <ListGroup>
                    <ListGroup.Item className="enc-data-list">
                        Call List
                    </ListGroup.Item>
                    <ListGroup.Item className="enc-data-list">
                        <div className="row">                          
                                <div className="col">
                                    Opportunity Name {dateSort === 0 || dateSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className = { classes[0]} onClick={() => headerSort(0, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className = { classes[0]} onClick={() => headerSort(0, 2)} />}
                                </div>
                                <div className="col">
                                    Description {companySort === 0 || companySort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[1]} onClick={() => headerSort(1, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[1]} onClick={() => headerSort(1, 2)} />}
                                </div>
                                <div className="col">
                                    Account Name {callerSort === 0 || callerSort === 1 ? < FontAwesomeIcon icon={faSortAmountDown} className={classes[2]} onClick={() => headerSort(2, 1)} /> : < FontAwesomeIcon icon={faSortAmountUp} className={classes[2]} onClick={() => headerSort(2, 2)} />}
                                </div>  
                        </div>
                    </ListGroup.Item>
                    {calls.length === 0 && <ListGroup.Item>No Calls To Display</ListGroup.Item>}
                    {calls.map((m, i) => {
                        let d = new Date(`${m.callTime}`),
                            minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes(),
                            hours = d.getHours().toString().length === 1 ? '0' + d.getHours() : d.getHours(),
                            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                        let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} - ${hours}:${minutes}`
                        return (
                            <ListGroup.Item key={`Call-${i}`} as={Link} to={GenerateLink(m)} className="click-list" className="click-list">
                                <div className="row">
                                    <div className="col">
                                        {date}
                                    </div>
                                    <div className="col">
                                        {m.companyName}
                                    </div>
                                    <div className="col">
                                        {m.callerName}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>

            </div>
        </>
    )
}
