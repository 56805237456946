import React, { useState, useEffect } from 'react';
import CalculatorForm from './CalculatorForm';
import { ListGroup, Modal, Button, } from 'react-bootstrap';
import { GetCalculatorList, DeleteCalculator } from '../API/Calculator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export default props => {
    const [calculators, setCalculators] = useState([])
    const [show, setShow] = useState(false);
    const [calculator, setCalculator] = useState({
        name: "",
        id: 0,
    });
    

    useEffect(()=>{
        LoadCalculatorList();
    },[])

    const LoadCalculatorList = () => {
        GetCalculatorList()
        .then(res=>{
            setCalculators(res.data)
        })
    }

    const removeCalculator = (id) => {
            
            DeleteCalculator(id)
            .then(res=>{
                LoadCalculatorList()
            })
        setShow(false)
        
    }
    let history = useHistory();
    const navigate = (id) =>{
        history.push(`${props.match.url}/${id}`)
    }


    const handleShowModal = (id, name) => {
        setShow(true)
        setCalculator({
            id: id,
            name: name,
        });

    }


    return (
        <>
            <div className="container">
                <ListGroup>
                    <ListGroup.Item className="click-list">
                        Calculators
                    </ListGroup.Item>
                    {calculators.map((m, i) => {
                        return (
                            <ListGroup.Item key={`calc-${i}`} className="click-list">
                                <div className='row'>
                                    <div className="col" onClick={()=>navigate(m.calculatorId)}>
                                    {m.calculatorName}
                                    </div>
                                    <div className='col-1 text-right'>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleShowModal(m.calculatorId, m.calculatorName)} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirm you would like to permanently delete {calculator.name}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="enc-button"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => removeCalculator(calculator.id)}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <CalculatorForm rl={LoadCalculatorList} />
        </>
    )
}
