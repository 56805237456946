import React, { useState, useEffect } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { GetJobReadyItemList } from '../../API/JobReadyInventory';
import { DropdownList } from 'react-widgets';
import { Link } from 'react-router-dom';
import { GetYardInventory, PostYardInventory } from '../../API/Inventory';
import { GetBulkItemList } from '../../API/InventoryBulk';
import EditGrid from '../../Components/Grid/EditGrid';

export default props => {

    const [Inventory, setInventory] = useState({
        "yardId": 0,
        "yardName": "",
        "bulkItems": [],
        "jobReadyItems": [],
        "assets": []
    })

    const [sortBy, setSortBy] = useState({ bulkItems: [], jobReadyItems: [] })


    // Global List of Bulk Items for adding/removing.
    const [bulkItems, setBulkItems] = useState([]);
    const [selectedBulk, setSelectedBulk] = useState(null)
    const [jobReadyItems, setJobReadyItems] = useState([]);
    const [selectedJobReady, setSelectedJobReady] = useState(null);

    useEffect(() => {
        let id = props.match.params.yardId;
        LoadJobReadyItems();
        LoadInventory(id);
        LoadBulkItemList()
    }, [props.match.params.yardId])
        
    const LoadJobReadyItems = () => {
        GetJobReadyItemList()
            .then(res => {
                setJobReadyItems(res.data)
            })
    }

    const LoadInventory = (id) => {
        let sortObject = { bulkItems: [], jobReadyItems: [] }
        const AddTotals = (result) => {
            result.bulkItems.forEach(category => {
                sortObject.bulkItems.push({ sortKey: "", sortDesc: true })
                category.items.forEach(item => {
                    item.newStock = item.amount
                })
            })
            result.jobReadyItems.forEach(category => {
                sortObject.bulkItems.push({ sortKey: "", sortDesc: true })
                category.items.forEach(item => {
                    item.newStock = item.amount
                })
            })

            setSortBy(sortObject)
            return result;
        }
        GetYardInventory(id)
            .then(res => {
                let totals = AddTotals(res.data)
                setInventory(totals)
            })
    }



    const LoadBulkItemList = () => {
        GetBulkItemList()
            .then(res => {
                setBulkItems(res.data)
            })
    }

    const saveInventory = () => {
        let payload = { ...Inventory }
        payload.bulkItems.forEach(category => {
            category.items.forEach(e =>
                e.adjustment = parseInt(e.adjustment)
            )
        })
        payload.jobReadyItems.forEach(category => {
            category.items.forEach(e => {
                e.adjustment = parseInt(e.adjustment)
                e.damagedAdjustment = parseInt(e.damagedAdjustment)
            })
        })
        PostYardInventory(payload)
            .then(res => {
                LoadInventory(payload.yardId)
            })
    }

    const AddBulkItem = () => {
        if (!selectedBulk) {
            return;
        }

        let item = {
            inventoryId: 0,
            name: selectedBulk.bulkItemName,
            amount: 0,
            itemId: selectedBulk.bulkItemId,
            adjustment: 0,
            newAmount: 0
        }

        let s = { ...Inventory }
        let category;
        if (selectedBulk.category) {
            category = s.bulkItems.find(x => x.categoryName.toLowerCase() === selectedBulk.category.toLowerCase())
            if (!category) {
                s.bulkItems.push({ categoryName: selectedBulk.category, items: [] })
                category = s.bulkItems.find(x => x.categoryName.toLowerCase() === selectedBulk.category.toLowerCase())
            }
        } else {
            category = s.bulkItems.find(x => x.categoryName.toLowerCase() === 'other')
            if (!category) {
                s.bulkItems.push({ categoryName: "Other", items: [] })
                category = s.bulkItems.find(x => x.categoryName.toLowerCase() === 'other')
            }
        }
        category.items.unshift(item)
        setInventory(s)
        setSelectedBulk(null)
    }

    const AddJobReadyItem = () => {
        if (!selectedJobReady) {
            return;
        }

        let item = {
            inventoryId: 0,
            name: selectedJobReady.name,
            amount: 0,
            itemId: selectedJobReady.jobReadyItemId,
            damagedAmount: 0,
            damagedAdjustment: 0,
            adjustment: 0,
            newAmount: 0
        }

        let s = { ...Inventory }

        let category;
        if (selectedJobReady.category) {
            category = s.jobReadyItems.find(x => x.categoryName.toLowerCase() === selectedJobReady.category.toLowerCase())
            if (!category) {
                s.jobReadyItems.push({ categoryName: selectedJobReady.category, items: [] })
                category = s.jobReadyItems.find(x => x.categoryName.toLowerCase() === selectedJobReady.category.toLowerCase())
            }
        } else {
            category = s.jobReadyItems.find(x => x.categoryName.toLowerCase() === 'other')
            if (!category) {
                s.jobReadyItems.push({ categoryName: "Other", items: [] })
                category = s.jobReadyItems.find(x => x.categoryName.toLowerCase() === 'other')
            }
        }
        category.items.unshift(item)
        setInventory(s)
        setSelectedJobReady(null)
    }


    const adjustAmount = (cat, catIndex, index, value) => {
        let s = { ...Inventory }
        if (new RegExp('^-?([0-9]{0,10})$').test(value)) {
            s[cat][catIndex].items[index].adjustment = value
            s[cat][catIndex].items[index].newStock = CalculateNewAmount(s[cat][catIndex].items[index].amount, value)
            setInventory(s)
        }
    }

    const adjustDamagedAmount = (cat, catIndex, index, value) => {
        let s = { ...Inventory }
        if (new RegExp('^-?([0-9]{0,10})$').test(value)) {
            s[cat][catIndex].items[index].damagedAdjustment = value
            setInventory(s)
        }
    }

    const CalculateNewAmount = (Amount, Adjustment) => {
        Amount = Amount === "" ? 0 : parseInt(Amount)
        Adjustment = Adjustment === "" ? 0 : parseInt(Adjustment)
        let NewAmount = Amount + Adjustment
        return NewAmount
    }

    const CheckJobReady = (item) => {
        for (let i = 0; i < Inventory.jobReadyItems.length; i++) {
            if (Inventory.jobReadyItems[i].items.find(x => x.itemId === item.jobReadyItemId)) {
                return false;
            }
        }
        return true;
    }

    const CheckBulk = (item) => {
        for (let i = 0; i < Inventory.bulkItems.length; i++) {
            if (Inventory.bulkItems[i].items?.find(x => x.itemId === item.inventoryBulkItemId)) {
                return false;
            }
        }
        return true;
    }

    const sortReplace = (type, categoryIndex, payload) => {

        let s = { ...Inventory }
        s[type][categoryIndex].items = payload
        setInventory(s)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>{Inventory.yardName} Inventory Adjustment</h3>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className="row mb-2">
                        <div className="col">
                            <h4>Bulk Items</h4>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <DropdownList
                                placeholder="Select Bulk Item"
                                data={bulkItems.filter(item => CheckBulk(item))}
                                textField='bulkItemName'
                                value={selectedBulk}
                                onChange={(e) => setSelectedBulk(e)}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col text-right">
                            <Button className="enc-button" onClick={AddBulkItem}>Add Bulk Item</Button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col mb-2">
                            <h4>Job Ready Items</h4>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <DropdownList
                                placeholder="Select Job Ready Item"
                                data={jobReadyItems.filter(item => CheckJobReady(item))}
                                textField='name'
                                value={selectedJobReady}
                                onChange={(e) => setSelectedJobReady(e)}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col text-right">
                            <Button className="enc-button" onClick={AddJobReadyItem} >Add Job Ready Item</Button>
                        </div>
                    </div>
                </div>
            </div>

            <form action="#" onSubmit={(e) => { e.preventDefault(); saveInventory() }}>


                <div className="row">
                    <div className="col">
                        <h4>Job Ready Items</h4>
                    </div>
                </div>
                {Inventory.jobReadyItems.map((cat, catIndex) => {
                    function ChangeRow(index, value) {
                        adjustAmount("jobReadyItems", catIndex, index, value)
                    }
                    function ChangeDamaged(index, value) {
                        adjustDamagedAmount("jobReadyItems", catIndex, index, value)
                    }
                    function callBackSort(value, sort) {
                        sortReplace("jobReadyItems", catIndex, value)
                        let s = { ...sortBy }
                        s.jobReadyItems[catIndex] = sort
                        setSortBy(s)
                    }
                    return (
                        <EditGrid
                            key={`grid-${catIndex}`}
                            headerName={cat.categoryName}
                            headers={[
                                { key: 'name', name: 'Item Name' },
                                { key: 'damagedAmount', name: "Damaged Amount" },
                                { key: 'damagedAdjustment', name: "Damaged Adjustment", input: true, changeFunction: ChangeDamaged },
                                { key: 'amount', name: 'Amount' },
                                { key: 'adjustment', name: "Change Amount", input: true, changeFunction: ChangeRow }
                            ]}
                            data={cat.items}
                            sortCallBack={callBackSort}
                            sortBy={sortBy.jobReadyItems[catIndex]}
                        />
                    )
                }
                )}


                <div className="row">
                    <div className="col">
                        <h4>Bulk Items</h4>
                    </div>
                </div>
                {Inventory.bulkItems.map((cat, catIndex) => {
                    function ChangeRow(index, value) {
                        adjustAmount("bulkItems", catIndex, index, value)
                    }
                    function callBackSort(value, sort) {
                        sortReplace("bulkItems", catIndex, value)
                        let s = { ...sortBy }
                        s.bulkItems[catIndex] = sort
                        setSortBy(s)
                    }
                    return (
                        <EditGrid
                            key={`grid2-${catIndex}`}
                            headerName={cat.categoryName}
                            headers={[
                                { key: 'name', name: 'Item Name' },
                                { key: 'amount', name: 'Amount' },
                                { key: 'adjustment', name: "Change Amount", input: true, changeFunction: ChangeRow },
                                { key: 'newStock', name: "New Stock(Estimated)" }
                            ]}
                            data={cat.items}
                            sortCallBack={callBackSort}
                            sortBy={sortBy.bulkItems[catIndex]}
                        />
                    )
                }
                )}
                <div className="row mb-5">
                    <div className="col text-right">
                        <Button type='submit' className="enc-button">Save Adjustments</Button>
                    </div>
                </div>
            </form>

            <ListGroup className="mb-5">
                <ListGroup.Item className="click-list">
                    <div className="row">
                        <div className="col">
                            Name
                            </div>
                        <div className="col">
                            Serial Number
                            </div>
                        <div className="col">
                            Status
                            </div>
                    </div>
                </ListGroup.Item>
                {Inventory.assets.map(m =>
                    <ListGroup.Item key={`asset-${m.serialNumber}`} className="click-list" as={Link} to={`/Admin/Inventory/Asset/${m.serializedItemId}`}>
                        <div className="row">
                            <div className="col">
                                {m.name}
                            </div>
                            <div className="col">
                                {m.serialNumber}
                            </div>
                            <div className="col">
                                {m.status}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>

        </div>
    )
}
