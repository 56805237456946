import APIRequest from './MainAPICall/api';
import config from '../APIConfig';

export const GetJobSteps = () => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJobSteps",
        method: "get"
    });
}

export const GetJobList = (canceled = false, step = -1, oppId = 0) => {
    return APIRequest({
        url: config.baseURL + `/Job/JobList?canceled=${canceled}&JobStep=${step}&OpportunityId=${oppId}`,
        method: "get"
    });
}

export const GetJobListOptions = (id) => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJobListOptions",
        method: "post",
        body:{
            id: id
        }
    });
}

export const GetJobProfile = (id) => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJobProfile",
        method: "post",
        body:{
            id: id
        }
    });
}

export const GetJob = (id) => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJob",
        method: "post",
        body:{
            id: id
        }
    });
}

export const GetJobEdit = (id) => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJobEdit",
        method: "post",
        body:{
            id: id
        }
    });
}

export const SaveJob = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Job/SaveJob",
        method: "post",
        body: payload

    });
}

export const SaveJobNote = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Job/SaveJobNote",
        method: "post",
        body: payload
    });
}

export const GetJobNote = (id) => {
    return APIRequest({
        url: config.baseURL + "/Job/GetJobNote",
        method: "post",
        body:{
            id: id
        }
    });
}

export const GetActiveJobs = () => {
    return APIRequest({
        url: config.baseURL + "/Job/ActiveJobs",
        method: "get"
    });
}

export const GetJobStageList = () => {
    return APIRequest({
        url: config.baseURL + "/Job/JobStageList",
        method: "get"
    });
}

export const CloseStep = (stepId, jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/CloseStep?jobId=${jobId}&stepId=${stepId}`,
        method: "get"
    });
}

export const CloseStepPermission = (stepId, jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/CloseStepPermission?jobId=${jobId}&stepId=${stepId}`,
        method: "get"
    });
}

export const JobSearch = (payload) => {
    return APIRequest({
        url: config.baseURL + "/Job/JobSearch",
        method: "post",
        body: payload
    });
}

export const CheckBilling = (jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/CheckBilling?jobId=${jobId}`,
        method: "get"
    });
}
export const CompleteBilling = (jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/CompleteBilling?jobId=${jobId}`,
        method: "get"
    });
}

export const GetJobNoteList = (jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/JobNoteList?jobId=${jobId}`,
        method: "get"
    });
}

export const CheckRevertScopePermission = () => {
    return APIRequest({
        url: config.baseURL + `/Job/CheckRevertScopePermission`,
        method: "get"
    });
}

export const RevertScope = (jobId) => {
    return APIRequest({
        url: config.baseURL + `/Job/RevertScope?jobId=${jobId}`,
        method: "get"
    });
}
export const CheckDrillingAuth = () => {
    return APIRequest({
        url: config.baseURL + `/Job/CheckDrillingAuth`,
        method: "get"
    });
}