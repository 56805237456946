import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DeleteInventoryCategory, GetInventoryCategoryList } from '../../API/Inventory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default props => {
    const [categories, setCategories] = useState([])

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [stagedForDelete, setStagedForDelete] = useState(0);

    useEffect(() => {
        GetList()
    }, [])

    const GetList = () => {
        GetInventoryCategoryList()
            .then(res => {
                setCategories(res.data)
            })
    }

    const openConfirmDelete = (id) => {
        setStagedForDelete(id);
        setConfirmDelete(true);
    }

    const deleteCategory = () => {
        let id = categories[stagedForDelete].inventoryCategoryId
        DeleteInventoryCategory(id)
            .then(() => {
                GetList()
                setConfirmDelete(false);
            })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Inventory Categories</h3>
                </div>
                <div className="col text-right">
                    <Button className="enc-button" as={Link} to={`${props.match.url}/new`}>New Category</Button>
                </div>
            </div>

            <ListGroup>
                <ListGroup.Item className="enc-list">
                    Inventory Categories
                </ListGroup.Item>
                {categories.length === 0 && <ListGroup.Item>No Categories Exist</ListGroup.Item>}
                {categories.map((m, i) => {
                    return (
                        <ListGroup.Item  key={`category-${i}`} >
                            <div className="row">

                                <div className="col-11">
                                    <Link to={`${props.match.url}/${m.inventoryCategoryId}`} style={{ color: "black" }}>
                                        <div className="row">


                                            <div className="col">
                                                {m.name}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faTrash} className="clickable" onClick={() => openConfirmDelete(i)} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will delete this Category. Please confirm you would like to take this action.
                    </Modal.Body>
                <Modal.Footer>
                    <Button className="enc-button" onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteCategory} >
                        Confirm
                        </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
