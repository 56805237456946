import React, { useState, useEffect } from "react";
import {
    GetDocumentAndPolicyList,
    DeleteDocumentAndPolicy,
    GetDocument,
} from "../API/DocumentAndPolicy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { FilePond } from 'react-filepond';

export default () => {
    const [fileName, setFileName] = useState("");
    const [documents, setDocuments] = useState([]);
    const [confirm, setConfirm] = useState({
        name: "",
        id: 0,
    });
    const [show, setShow] = useState(false);

    // FilePondConfiguration //
    const FilePondSettings = (filename) => {
        let token;

        const LS = localStorage.getItem("BAENCToken");
        const SS = sessionStorage.getItem("BAENCToken");

        if (LS) {
            token = LS;
        } else if (SS) {
            token = SS;
        }

        return {
            url: "/api/DocumentAndPolicy",
            process: {
                url: "/SaveDocumentAndPolicy",
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                ondata: (formData) => {
                    formData.append("documentName", filename);
                    return formData;
                },
                onload: (res) => {
                    loadDocuments();
                    setFileName("");
                },
            },
            load: null,
            revert: null,
            restore: null,
            fetch: null,
            patch: null,
        };
    };

    useEffect(() => {
        loadDocuments();
    }, []);

    const loadDocuments = () => {
        GetDocumentAndPolicyList().then((res) => {
            setDocuments(res.data);
        });
    };

    const confirmDelete = (obj) => {
        setShow(true);
        setConfirm({
            id: obj.documentId,
            name: obj.name,
        });
    };

    const deleteDoc = () => {
        setShow(false);
        DeleteDocumentAndPolicy(confirm.id).then((res) => {
            if (res.success) {
                loadDocuments();
            }
        });
    };

    const downloadDoc = (id, name) => {
        let download = require("downloadjs");
        GetDocument(id)
            .then((res) => {
                download(res, name);
            });
    };

    return (
        <div className="container mt-5">
            <div className="container p-2 p1-border">
                <div className="row">
                    <div className="col text-center">
                        <h3>Upload New Document</h3>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-lg-5 my-auto">
                        <input
                            type="text"
                            className="enc-input-100 my-auto"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                            placeholder="Display Name"
                            required
                        />
                    </div>
                    <div className="col-lg-7">
                        {fileName !== "" ? (
                            <FilePond
                                files={[]}
                                className="mt-3"
                                allowMultiple={false}
                                server={FilePondSettings(fileName)}
                                credits={false}
                            />
                        ) : (
                                <strong>
                                    Please add a document name before uploading file.
                                </strong>
                            )}
                    </div>
                </div>
            </div>
            <ListGroup>
                <ListGroup.Item className="enc-list mt-1">
                    Current Documents
                </ListGroup.Item>
                {documents.length === 0 && (
                    <ListGroup.Item className="enc-list">
                        No Current Documents
                    </ListGroup.Item>
                )}
                {documents.map((m) => {
                    return (
                        <ListGroup.Item key={`Document-${m.documentId}`}>
                            <div className="row" id={m.documentId}>
                                <div className="col-sm-1 my-auto">
                                    <FontAwesomeIcon
                                        className="clickable"
                                        icon={faDownload}
                                        onClick={() => downloadDoc(m.documentId, m.filename)}
                                    />
                                </div>
                                <div className="col-sm-10 my-auto">
                                    <h5> {m.name}</h5>
                                </div>
                                <div className="col-sm-1 my-auto">
                                    <FontAwesomeIcon
                                        className="clickable"
                                        icon={faTrash}
                                        onClick={() => confirmDelete(m)}
                                    />{" "}
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>

            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirm you would like to permanently delete {confirm.name}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="enc-button"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteDoc}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
